import React, { Fragment } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  Fab,
  Tooltip,
  IconButton,
  TextField,
  Button,
  Box,
  Paper,
} from "@material-ui/core";

import {
  historyPush,
  withHistory,
  TextInput,
  formatMessage,
  PublishedComponent,
  FormattedMessage,
  FormPanel,
  Contributions,
} from "@openimis/fe-core";
import {
  ProgressOrError,
  Form,
  withModulesManager,
  journalize,
  formatMessageWithValues,
  formatDateFromISO,
  Helmet,
} from "@openimis/fe-core";
import { QUALITY_ASSURRANCE, ACCREDITATION } from "../constants";

const styles = (theme) => ({
  tableTitle: theme.table.title,
  page: theme.page,
  paper: theme.paper.paper,
  item: theme.paper.item,
  title: theme.paper.title,
  fullHeight: {
    height: "100%",
  },
});

class ProjectsMasterPanel extends FormPanel {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, updateAttribute, readOnly = false, edited, projectUuid, type } = this.props;

    return (
      <Fragment>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>
            {projectUuid ? "Accreditation Details" : "Add Accreditation"}
          </Typography>
          <div className={classes.page}>
            <Grid container spacing={2}>
              {edited && edited[0] && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label={"Accreditation Name"}
                    value={edited ? edited[0]?.projectName : ""}
                    disabled={projectUuid}
                    onChange={(e) => updateAttribute("projectName", e.target.value)}
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <PublishedComponent
                  pubRef="location.HealthFacilityPicker"
                  value={edited[0]?.healthFacility ?? null}
                  readOnly={projectUuid}
                  onChange={(hf) => updateAttribute("healthFacility", hf)}
                  ignoreLocation={true}
                />
              </Grid>
              {projectUuid && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    disabled={edited}
                    value={
                      !!edited
                        ? formatMessage(this.props.intl, "accredation", `project.status.${edited[0]?.status}`)
                        : ""
                    }
                    label="Status"
                  />
                </Grid>
              )}

              {projectUuid && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    value={
                      !!edited
                        ? formatDateFromISO(this.props.modulesManager, this.props.intl, edited[0]?.createdDate)
                        : ""
                    }
                    label="Date"
                    disabled={edited}
                  />
                </Grid>
              )}
            </Grid>

            <Box display="flex" justifyContent="end" sx={{ m: 2 }}>
              {!projectUuid && (
                <Button variant="contained" color="primary" onClick={this.props.createProject}>
                  Save
                </Button>
              )}
            </Box>
          </div>
        </Paper>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  mutation: state.accredation.mutation,
  submittingMutation: state.accredation.submittingMutation,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withModulesManager(
  injectIntl(
    withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(ProjectsMasterPanel)))),
  ),
);
