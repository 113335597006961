import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { STATUS, STATE } from "../constants";

class StatePicker extends Component {
  render() {
    return <ConstantBasedPicker module="accredation" label="state" constants={STATE} {...this.props} />;
  }
}

export default StatePicker;
