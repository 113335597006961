import React,{Fragment, useState} from 'react'
import {Button, Box, Typography} from "@material-ui/core"
import AttachFileIcon from '@material-ui/icons/AttachFile';
export default function FilePicker({handleChange}) {

  const [filename, setFileName]= useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        if (!file) return;
        const reader = new FileReader();
        reader.onloadend = () => {
          // FileReader result is the base64 representation of the file
          const base64File = reader.result;
          const correctData = base64File.split(",")[1]
          handleChange("insurees",correctData);
        };
        reader.readAsDataURL(file);
      };
  return (
    <Fragment>
    <input
      color="primary"
      type="file"
      onChange={handleFileChange}
      id="icon-button-file"
      style={{ display: 'none', }}
    />
    <label htmlFor="icon-button-file">
      <Button
        variant="contained"
        component="span"
        className={""}
        size="large"
        color="primary"
      >
        <Box sx={{display:'flex'}}>
        <AttachFileIcon className={""} />
         {
            filename&& <Typography>{filename}</Typography>
         } 
        </Box>
       
      </Button>
    </label>
  </Fragment>
  )
}
