import React, { Component } from "react";
import { ConstantBasedPicker, formatMessage } from "@openimis/fe-core";

import { PREAUTHORIZATION_STATUS } from "../constants";

class PreauthorizationStatusPicker extends Component {
  render() {
    return (
      <ConstantBasedPicker
        module="preauthorization"
        label="Status"
        constants={PREAUTHORIZATION_STATUS}
        {...this.props}
      />
    );
  }
}

export default PreauthorizationStatusPicker;
