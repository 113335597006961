import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { Searcher } from "@openimis/fe-core";
import PreauthorizationFilter from "./PreauthorizationFilter";
import {
  withModulesManager,
  formatMessageWithValues,
  formatMessage,
  PublishedComponent,
} from "@openimis/fe-core";
import { fetchMyPreauthorization } from "../actions";

const PREAUTHORIZATION_SEARCHER_CONTRIBUTION_KEY = "preauthorization.Searcher";

const styles = (theme) => ({});

class PreAuthorizationSearcher extends Component {
  state = {
    random: null,
    filters: {},
  };

  constructor(props) {
    super(props);
    this.rowsPerPageOptions = props.modulesManager.getConf(
      "fe-preauthorization",
      "preauthorizationFilter.rowsPerPageOptions",
      [10, 20, 50, 100]
    );
    this.defaultPageSize = props.modulesManager.getConf(
      "fe-preauthorization",
      "preauthorizationFilter.defaultPageSize",
      10
    );
    this.highlightAmount = parseInt(
      props.modulesManager.getConf(
        "fe-preauthorization",
        "preauthorizationFilter.highlightAmount",
        0
      )
    );
    this.highlightAltInsurees = props.modulesManager.getConf(
      "fe-preauthorization",
      "preauthorizationFilter.highlightAltInsurees",
      true
    );
    this.extFields = props.modulesManager.getConf(
      "fe-preauthorization",
      "extFields",
      []
    );
  }

  handleFilter = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...this.state.filters,
        [key]: value,
      },
    }));
  };

  fetch = () => {
    this.props.fetchMyPreauthorization(
      this.props.modulesManager,
      this.state.filters
    );
  };

  rowIdentifier = (r) => r.uuid;

  forcedFilters() {
    return !this.props.forcedFilters
      ? []
      : [...this.props.forcedFilters.filter((f) => f.id !== "random")];
  }

  filterIsEmpty = () => {
    return (
      Object.keys(this.state.filters).length === 0 ||
      Object.values(this.state.filters).every((x) => x === null || x === "")
    );
  };

  filterPreauthorization = () => {
    return this.props?.preauthorizations?.filter(
      (preauth) =>
        preauth?.icd?.name == this.state.filters.icd?.name ||
        preauth?.service?.name == this.state.filters.service?.name ||
        preauth?.refHf?.code == this.state.filters.refHf?.code ||
        preauth?.recHf?.code == this.state.filters.recHf?.code ||
        preauth?.insuree?.chfId == this.state.filters.chfId ||
        preauth.praStatus == this.state.filters.praStatus
    );
  };

  feedbackColFormatter = (c) =>
    !!this.props.feedbackColFormatter
      ? this.props.feedbackColFormatter(c)
      : formatMessage(
          this.props.intl,
          "preauthorization",
          `preauthorization.Status.${c.praStatus}`
        );
  reviewColFormatter = (c) =>
    !!this.props.reviewColFormatter
      ? this.props.reviewColFormatter(c)
      : formatMessage(
          this.props.intl,
          "preauthorization",
          `preauthorization.Status.${c.praStatus}`
        );

  headers = () => {
    var result = [
      formatMessage(
        this.props.intl,
        "preauthorization",
        "preauthorization.ReceivingHF"
      ),
      formatMessage(
        this.props.intl,
        "preauthorization",
        "preauthorization.RefferingHF"
      ),
      formatMessage(
        this.props.intl,
        "preauthorization",
        "preauthorization.Insuree"
      ),
      formatMessage(
        this.props.intl,
        "preauthorization",
        "preauthorization.Diagnosis"
      ),
      formatMessage(
        this.props.intl,
        "preauthorization",
        "preauthorization.MedicalService"
      ),
      formatMessage(
        this.props.intl,
        "preauthorization",
        "preauthorization.Status"
      ),
    ];
    return result;
  };

  sorts = () => {
    var result = [
      [
        this.props.modulesManager.getRef("location.HealthFacilityPicker.sort"),
        true,
      ],
      [
        this.props.modulesManager.getRef("location.HealthFacilityPicker.sort"),
        true,
      ],
      [this.props.modulesManager.getRef("insuree.InsureePicker.sort"), true],
      ["icd", true],
      ["service"],
      ["praStatus", true],
    ];
    return result;
  };

  aligns = () => {
    return [, , , , , , , "right", "right"];
  };

  itemFormatters = () => {
    var result = [
      (c) => (
        <PublishedComponent
          readOnly={true}
          pubRef="location.HealthFacilityPicker"
          withLabel={false}
          value={c?.recHf}
        />
      ),

      (c) => (
        <PublishedComponent
          readOnly={true}
          pubRef="location.HealthFacilityPicker"
          withLabel={false}
          value={c?.refHf}
        />
      ),

      (c) => (
        <PublishedComponent
          readOnly={true}
          pubRef="insuree.InsureePicker"
          withLabel={false}
          value={c.insuree}
        />
      ),

      (c) => (
        <PublishedComponent
          readOnly={true}
          pubRef="location.HealthFacilityPicker"
          withLabel={false}
          value={c?.icd}
        />
      ),
      (c) => `${c.service?.name}`,
      (c) =>
        formatMessage(
          this.props.intl,
          "preauthorization",
          `preauthorization.Status.${c.praStatus}`
        ),
    ];
    if (!!this.extFields && !!this.extFields.length) {
      this.extFields.forEach((f) => {
        result.push((c) =>
          !!c.jsonExt ? String(_.get(JSON.parse(c.jsonExt), f, "-")) : ""
        );
      });
    }
    return result;
  };
  rowLocked = (selection, preauthorization) =>
    !!preauthorization.clientMutationId;
  rowHighlighted = (selection, preauthorization) =>
    !!this.highlightAmount && preauthorization.praStatus > this.highlightAmount;
  rowHighlightedAlt = (selection, preauthorization) =>
    !!this.highlightAltInsurees &&
    selection.filter((c) => _.isEqual(c.insuree, preauthorization.insuree))
      .length &&
    !selection.includes(preauthorization);

  compareByPraStatus = (a, b) => {
    const praStatusA = a.validityFrom;
    const praStatusB = b.validityFrom;

    if (praStatusA < praStatusB) {
      return 1;
    }
    if (praStatusA > praStatusB) {
      return -1;
    }
    return 0;
  };

  render() {
    const {
      intl,
      preauthorizations,
      preAuthorizationPageInfo,
      fetchingPreAuthorization,
      fetchedPreAuthorization,
      errorPreAuthorization,
      defaultFilters,
      cacheFiltersKey,
      onDoubleClick,
      actionsContributionKey,
    } = this.props;
    const filterredpre = this.filterPreauthorization();
    const con = this.filterIsEmpty();

    const sorted_data = preauthorizations
      ?.slice()
      .sort(this.compareByPraStatus);

    const sorted_filtered = filterredpre?.slice().sort(this.compareByPraStatus);
    const arrayL = filterredpre ? filterredpre.length : 0;
    let count = !!this.state.random && this.state.random.value;
    if (!count) {
      count = con ? preAuthorizationPageInfo.totalCount : arrayL;
    }

    return (
      <Fragment>
        <PreauthorizationFilter
          handleFilter={this.handleFilter}
          filters={this.state.filters}
        />
        <Searcher
          module="preauthorization"
          defaultFilters={defaultFilters}
          cacheFiltersKey={cacheFiltersKey}
          items={con ? sorted_data : sorted_filtered}
          itemsPageInfo={preAuthorizationPageInfo}
          fetchingItems={fetchingPreAuthorization}
          fetchedItems={fetchedPreAuthorization}
          errorItems={errorPreAuthorization}
          contributionKey={PREAUTHORIZATION_SEARCHER_CONTRIBUTION_KEY}
          tableTitle={formatMessageWithValues(
            intl,
            "preauthorization",
            "Preauthorization.found",
            { count }
          )}
          rowsPerPageOptions={this.rowsPerPageOptions}
          defaultPageSize={this.defaultPageSize}
          fetch={this.fetch}
          rowIdentifier={this.rowIdentifier}
          filtersToQueryParams={this.filtersToQueryParams}
          rowLocked={this.rowLocked}
          rowHighlighted={this.rowHighlighted}
          rowHighlightedAlt={this.rowHighlightedAlt}
          headers={this.headers}
          itemFormatters={this.itemFormatters}
          aligns={this.aligns}
          defaultOrderBy="praStatus"
          sorts={this.sorts}
          onDoubleClick={onDoubleClick}
          actionsContributionKey={actionsContributionKey}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  preauthorizations: state.preauthorization.preauthorizations,
  preAuthorizationPageInfo: state.preauthorization.preAuthorizationPageInfo,
  fetchingPreAuthorization: state.preauthorization.fetchingPreAuthorization,
  fetchedPreAuthorization: state.preauthorization.fetchedPreAuthorization,
  errorPreAuthorization: state.preauthorization.errorPreAuthorization,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchMyPreauthorization }, dispatch);
};

export default withModulesManager(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(withTheme(withStyles(styles)(PreAuthorizationSearcher))))
);
