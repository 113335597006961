import React, { Component } from "react";
import { withTheme, withStyles, Paper, Box } from "@material-ui/core";
import CircularMetric from "./CircularMetric"; // Import the new CircularMetric component

const styles = (theme) => ({
  paper: {
    ...theme.paper.paper,
    padding: theme.spacing(1),
    textAlign: "center",
  },
  page: theme.page,
  circularMetricContainer: {
    margin: theme.spacing(1),
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap", // Enable wrapping
  },
  circularMetricItem: {
    margin: theme.spacing(2), // Add margin between items
  },
});

class Results extends Component {
  render() {
    const { classes, theme } = this.props;
    const passed = 70; // You can dynamically pass these values as props or state
    const failed = 30;

    return (
      <Paper className={classes.paper}>
        <div className={classes.page}>
          <Box className={classes.circularMetricContainer}>
            <Box className={classes.circularMetricItem}>
              <CircularMetric passed={passed} failed={failed} theme={theme} title={"Surgeon Form"} />
            </Box>
            <Box className={classes.circularMetricItem}>
              <CircularMetric passed={passed} failed={failed} theme={theme} title={"Urologist Form"} />
            </Box>
          </Box>
        </div>
      </Paper>
    );
  }
}

export default withTheme(withStyles(styles)(Results));
