import React, { Component } from "react";
import { connect } from "react-redux";
import { formatMessage, withModulesManager, withHistory, historyPush } from "@openimis/fe-core";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { bindActionCreators } from "redux";

import QualityAssuranceSearcher from "../components/QualityAssuranceSearcher";

const styles = (theme) => ({
  page: theme.page,
  fab: theme.fab,
  padding: theme.padding,
});
class QualityAssurancesPage extends Component {
  constructor(props) {
    super(props);
  }
  onAdd = () => {
    const { modulesManager, history } = this.props;
    if (modulesManager && history) {
      historyPush(modulesManager, history, "accreditation.route.addQA");
    }
  };

  onDoubleClick = (project) => {
    const { modulesManager, history } = this.props;
    if (modulesManager && history) {
      historyPush(modulesManager, history, "accreditation.route.addQA", [project.uuid]);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.page}>
        <QualityAssuranceSearcher onDoubleClick={this.onDoubleClick} />
        <div className={classes.fab}>
          <Fab color="primary" onClick={this.onAdd}>
            <AddIcon />
          </Fab>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default injectIntl(
  withModulesManager(
    withHistory(connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(QualityAssurancesPage)))),
  ),
);
