import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import {
  FormattedMessage,
  formatMessageWithValues,
  PublishedComponent,
  decodeId,
  Contributions,
  fetchHistoricalMutations,
} from "@openimis/fe-core";
import { Fab, Grid, Typography } from "@material-ui/core";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { createPolicyHolderInsureesBulk } from "../actions";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PolicyHolderContributionPlanBundlePicker from "../pickers/PolicyHolderContributionPlanBundlePicker";
import FilePicker from "../components/FilePicker";
import ErrorModal from "../components/ErrorModal.js";
import {
  ZERO,
  MAX_CLIENTMUTATIONLABEL_LENGTH,
  POLICYHOLDERINSUREE_CALCULATION_CONTRIBUTION_KEY,
  POLICYHOLDERINSUREE_CLASSNAME,
  RIGHT_CALCULATION_WRITE,
} from "../constants";

const styles = (theme) => ({
  item: theme.paper.item,
});

class CreatePolicyHolderInsureeBulkDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      policyHolderInsuree: {},
      jsonExtValid: true,
      openError: false,
    };
  }

  filterMutation = (mutation, mutations) => {
    let mutData = {};
    if (mutation) {
      mutData = mutations.filter(
        (mut) => mutation.clientMutationId === mut.clientMutationId
      );
    }
    return mutData;
  };
  componentDidUpdate(prevProps) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.fetchHistoricalMutations(5, null);
    }
  }

  handleCloseError=()=>{
    this.setState({ open: false, policyHolderInsuree: {} });
    window.location.reload();
  }

  handleOpen = () => {
    this.setState((_, props) => ({
      open: true,
      policyHolderInsuree: {
        policyHolder: props.policyHolder,
        policy: {},
      },
      jsonExtValid: true,
    }));
  };

  handleClose = () => {
    this.setState({ open: false, policyHolderInsuree: {} });
  };

  handleSave = () => {
    const { intl, policyHolder, onSave, createPolicyHolderInsureesBulk } =
      this.props;
    createPolicyHolderInsureesBulk(
      this.state.policyHolderInsuree,
      formatMessageWithValues(
        intl,
        "policyHolder",
        "CreatePolicyHolderInsureeBulk.mutationLabel",
        {
          tradeName: policyHolder.tradeName,
        }
      ).slice(ZERO, MAX_CLIENTMUTATIONLABEL_LENGTH)
    );
    onSave();
  };

  updateAttribute = (attribute, value) => {
    this.setState((state) => ({
      policyHolderInsuree: {
        ...state.policyHolderInsuree,
        [attribute]: value,
      },
    }));
  };

  canSave = () => {
    const { policyHolderInsuree, jsonExtValid } = this.state;
    return (
      !!policyHolderInsuree.policyHolder &&
      !!policyHolderInsuree.insurees &&
      !!policyHolderInsuree.contributionPlanBundle &&
      !!policyHolderInsuree.dateValidFrom &&
      !!jsonExtValid
    );
  };

  setJsonExtValid = (valid) => this.setState({ jsonExtValid: !!valid });

  render() {
    const { intl, classes } = this.props;
    const { openError, open, policyHolderInsuree } = this.state;
    let datas;
    if (Object.keys(this.props.mutation).length !== 0) {
      datas = this.filterMutation(this.props.mutation, this.props.mutations);
    }
    const errorData = datas ? datas[0] : null;
    return (
      <Fragment>
        <Fab size="small" color="primary" onClick={this.handleOpen}>
          <AddIcon />
        </Fab>
        <Dialog open={open} onClose={!errorData?this.handleClose:this.handleCloseError}>
          <DialogTitle>
             { !errorData?.error? <FormattedMessage
              module="policyHolder"
              id="policyHolderInsuree.createPolicyHolderInsuree"
            />: <Typography>The following Errors Occured</Typography>
            }
          </DialogTitle>
          <DialogContent>
            {errorData?.error ? (
              <ErrorModal data={errorData} />
            ) : (
              <Grid container direction="column" className={classes.item}>
                <Grid item className={classes.item}>
                  <PolicyHolderContributionPlanBundlePicker
                    withNull={true}
                    required                                   
                    policyHolderId={
                      !!policyHolderInsuree.policyHolder &&
                      decodeId(policyHolderInsuree.policyHolder.id)
                    }
                    value={
                      !!policyHolderInsuree.contributionPlanBundle &&
                      policyHolderInsuree.contributionPlanBundle
                    }
                    onChange={(v) =>
                      this.updateAttribute("contributionPlanBundle", v)
                    }
                  />
                </Grid>
                <Grid item className={classes.item}>
                               
                  <FilePicker handleChange={this.updateAttribute} />
                  {!this.state.policyHolderInsuree.insurees}
                </Grid>
                <Grid item className={classes.item}>
                  <PublishedComponent
                    pubRef="core.DatePicker"
                    module="policyHolder"
                    label="dateValidFrom"
                    required
                    onChange={(v) => this.updateAttribute("dateValidFrom", v)}
                  />
                </Grid>
                <Grid item className={classes.item}>
                  <PublishedComponent
                    pubRef="core.DatePicker"
                    module="policyHolder"
                    label="dateValidTo"
                    onChange={(v) => this.updateAttribute("dateValidTo", v)}
                    />
                </Grid>
               </Grid> 
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={!errorData?this.handleClose:this.handleCloseError} variant="outlined">
              <FormattedMessage module="policyHolder" id="dialog.cancel" />
            </Button>
            {!errorData?.error && (
              <Button
                onClick={this.handleSave}
                disabled={!this.canSave()}
                variant="contained"
                color="primary"
              >
                <FormattedMessage module="policyHolder" id="dialog.replace" />
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Fragment>

    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createPolicyHolderInsureesBulk, fetchHistoricalMutations },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  mutations: state.core.mutations,
  submittingMutation: state.policyHolder.submittingMutation,
  mutation: state.policyHolder.mutation,
});

export default injectIntl(
  withTheme(
    withStyles(styles)(
      connect(mapStateToProps, mapDispatchToProps)(CreatePolicyHolderInsureeBulkDialog)
    )
  )
);
