import { Grid } from "@material-ui/core";
import { PublishedComponent } from "@openimis/fe-core";
import React from "react";

const healthFacilityClaims = (props) => {
  const { values, setValues } = props;

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <PublishedComponent
          pubRef="core.DatePicker"
          value={values.date_from}
          module="claim"
          required
          label="Date From"
          onChange={(date_from) => setValues({ ...values, date_from })}
        />
      </Grid>
      <Grid item>
        <PublishedComponent
          pubRef="core.DatePicker"
          value={values.date_to}
          module="claim"
          required
          label="Date To"
          onChange={(date_to) => setValues({ ...values, date_to })}
        />
      </Grid>
      <Grid item>
        <PublishedComponent
          pubRef="location.HealthFacilityPicker"
          value={values.healthFacility}
          module="claim"
          label="Health Facility"
          onChange={(healthFacility) => setValues({ ...values, healthFacility })}
        />
      </Grid>
      <Grid item>
        <PublishedComponent
          pubRef="claim.ClaimStatusPicker"
          value={values.status}
          module="claim"
          label="Claim Status"
          onChange={(status) => setValues({ ...values, status })}
        />
      </Grid>
      <Grid item>
        <PublishedComponent
          pubRef="medical.DiagnosisPicker"
          value={values.diagnosis}
          module="medical"
          label="Diagnosis"
          onChange={(diagnosis) => setValues({ ...values, diagnosis })}
        />
      </Grid>

      {/* <div>{JSON.stringify(values)}</div> */}
    </Grid>
  );
};

export default healthFacilityClaims;
