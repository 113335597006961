import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Typography, List, ListItem, ListItemText } from "@material-ui/core";
import { withModulesManager, withHistory } from "@openimis/fe-core";
import { fetchAccredationDetailFormData } from "../actions";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
});

class FormViewer extends Component {
  componentDidMount() {
    const { uuid, fetchAccredationDetailFormData } = this.props;
    if (uuid) {
      fetchAccredationDetailFormData(uuid);
    }
  }

  renderData = (data) => {
    if (typeof data?.data === "string") {
      try {
        data = JSON.parse(data?.data); // Attempt to parse stringified JSON
        data = data?.value;
      } catch (error) {
        return <Typography>Error parsing data: {error.message}</Typography>;
      }
    }

    if (!data) {
      return <Typography>No data available</Typography>;
    }

    if (typeof data === "object" && Array.isArray(data)) {
      return (
        <List>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              {Object.entries(item)
                .filter(([key]) => !["meta", "__id", "__system"].includes(key)) // Exclude specified keys
                .map(([key, value], idx) => (
                  <ListItem key={`${index}-${idx}`} alignItems="flex-start">
                    <ListItemText
                      primary={
                        <span>
                          <strong>{this.formatKey(key)}:</strong> {value}
                        </span>
                      }
                    />
                  </ListItem>
                ))}
            </React.Fragment>
          ))}
        </List>
      );
    } else {
      return <Typography>{data.toString()}</Typography>;
    }
  };

  // Helper function to format the key
  formatKey = (key) => {
    if (typeof key !== "string") return key; // Ensure key is a string
    return key
      .replace(/_/g, " ") // Remove underscores
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  };

  // Helper function to format the key

  render() {
    const {
      classes,
      fetchedAccreditationDetailsFormData,
      fetchingAccreditationDetailsFormData,
      accreditationDetailsFormData,
    } = this.props;

    return (
      <Box className={classes.root}>
        {fetchingAccreditationDetailsFormData ? (
          <Typography>Loading data...</Typography>
        ) : accreditationDetailsFormData ? (
          this.renderData(accreditationDetailsFormData)
        ) : (
          <Typography>No data found</Typography>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  module: state.core?.savedPagination?.module,
  accreditationDetailsFormData: state.accredation.accreditationDetailsFormData,
  fetchingAccreditationDetailsFormData: state.accredation.fetchingAccreditationDetailsFormData,
  fetchedAccreditationDetailsFormData: state.accredation.fetchedAccreditationDetailsFormData,
  errorAccreditationDetailsFormData: state.accredation.errorAccreditationDetailsFormData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchAccredationDetailFormData }, dispatch);
};

export default withModulesManager(
  injectIntl(withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(FormViewer))))),
);
