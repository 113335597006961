import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Fab } from "@material-ui/core";
import { withTheme, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import {
  withHistory,
  historyPush,
  withModulesManager,
  journalize,
  coreConfirm,
} from "@openimis/fe-core";
import PreauthorizationSearcher from "../components/PreAuthorizationSearcher";
import { RIGHT_PRAUTHORIZATION_ADD } from "../constants";

const styles = (theme) => ({
  page: theme.page,
  fab: theme.fab,
});

class Preauthorizations extends Component {
  constructor(props) {
    super(props);
    let defaultFilters = props.modulesManager.getConf(
      "fe-claim",
      "healthFacilities.defaultFilters",
      {
        claimStatus: {
          value: 2,
          filter: "status: 2",
        },
      }
    );
    this.canSubmitClaimWithZero = props.modulesManager.getConf(
      "fe-claim",
      "canSubmitClaimWithZero",
      false
    );
    this.state = {
      defaultFilters,
      confirmedAction: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
      this.setState({ reset: this.state.reset + 1 });
    } else if (!prevProps.confirmed && this.props.confirmed) {
      this.state.confirmedAction();
    }
  }

  filtersToQueryParams = (filters) => {};

  onDoubleClick = (p, newTab = false) => {
    historyPush(
      this.props.modulesManager,
      this.props.history,
      "preauthorization.route.preauthorizationadd",
      [p.uuid],
      newTab
    );
  };

  onAdd = () => {
    historyPush(
      this.props.modulesManager,
      this.props.history,
      "preauthorization.route.preauthorizationadd"
    );
  };

  canAdd = () => {
    return true;
  };

  render() {
    const { classes, rights, generatingPrint } = this.props;
    if (!rights.filter((r) => r >= RIGHT_PRAUTHORIZATION_ADD).length)
      return null;
    return (
      <div className={classes.page}>
        <PreauthorizationSearcher
          defaultFilters={this.state.defaultFilters}
          onDoubleClick={this.onDoubleClick}
          processing={generatingPrint}
        />
        {!generatingPrint && rights.includes(RIGHT_PRAUTHORIZATION_ADD) && (
          <div className={classes.fab}>
            <Fab color="primary" disabled={!this.canAdd()} onClick={this.onAdd}>
              <AddIcon />
            </Fab>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights:
    !!state.core && !!state.core.user && !!state.core.user.i_user
      ? state.core.user.i_user.rights
      : [],
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      journalize,
      coreConfirm,
    },
    dispatch
  );
};

export default injectIntl(
  withModulesManager(
    withHistory(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(withTheme(withStyles(styles)(Preauthorizations)))
    )
  )
);
