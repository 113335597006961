import React, { Component } from "react";
import { Box, Fab } from "@material-ui/core";
import ProjectsMasterPanel from "../components/ProjectsMasterPanel";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { bindActionCreators } from "redux";
import {
  withHistory,
  historyPush,
  formatMessage,
  Helmet,
  clearCurrentPaginationPage,
  withModulesManager,
} from "@openimis/fe-core";

import ProjectForm from "../components/ProjectForm";
import { fetchQualityAssurance } from "../actions";
import isEqual from "lodash/isEqual";
import { QUALITY_ASSURRANCE } from "../constants";
import QualityAssuranceForm from "../components/QualityAssuranceForm";

const styles = (theme) => ({
  page: theme.page,
  fab: theme.fab,
});

class QualityAssurancePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edited: {},
    };
  }

  componentDidMount() {
    const projectUuid = this.props?.match?.params?.projectUuid;
    if (projectUuid) {
      // If projectUuid exists, fetch the accreditation data
      this.props.fetchQualityAssurance(projectUuid);
    }
  }
  componentDidUpdate(prevProps) {
    const { qualityAssurance } = this.props;
    // Check if accreditation data has changed and is different from the current state
    if (qualityAssurance && !isEqual(qualityAssurance, prevProps.qualityAssurance)) {
      this.setState({
        edited: { ...qualityAssurance[0] },
      });
    }
  }

  handleChange = (name, value) => {
    this.setState((prevState) => ({
      edited: {
        ...prevState.edited,
        [name]: value,
      },
    }));
  };

  onAdd = () => {
    const { modulesManager, history } = this.props;
    if (modulesManager && history) {
      historyPush(modulesManager, history, "accreditation.route.details");
    }
  };
  render() {
    const {
      classes,
      rights,
      modulesManager,
      history,
      intl,
      module,
      clearCurrentPaginationPage,
      qualityAssurance,
      fetchingQualityAssurance,
      fetchedQualityAssurance,
      errorQualityAssurance,
    } = this.props;
    const projectUuid = this.props?.match?.params?.projectUuid;
    const projectId = 1;
    console.log("Edited", this.state.edited);

    return (
      <div className={""}>
        <Helmet title={formatMessage(intl, "accredation", "projects.page.title")} />
        {
          <QualityAssuranceForm
            edited={this.state.edited}
            updateAttribute={this.handleChange}
            projectUuid={projectUuid}
            projectId={projectId}
            type={QUALITY_ASSURRANCE}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  module: state.core?.savedPagination?.module,
  fetchingQualityAssurance: state.accredation.fetchingQualityAssurance,
  fetchedQualityAssurance: state.accredation.fetchedQualityAssurance,
  qualityAssurance: state.accredation.qualityAssurance,
  errorQualityAssurance: state.accredation.errorQualityAssurance,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clearCurrentPaginationPage, fetchQualityAssurance }, dispatch);

export default injectIntl(
  withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(QualityAssurancePage)))),
);
