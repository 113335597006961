import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";

import {
  withTheme,
  withStyles,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";

import {
  ProgressOrError,
  formatMessageWithValues,
  Helmet,
  withModulesManager,
  journalize,
  withHistory,
} from "@openimis/fe-core";
import { fetchAllForms } from "../actions";

const styles = (theme) => ({
  paper: theme.paper.paper,
  title: theme.paper.title,
  page: theme.page,
  selectedFormContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.selected,
  },
});

class AllForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedForm: null,
      anchorEl: null,
      openConfigModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchAllForms();
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleFormClick = (form) => {
    this.setState((prevState) => ({
      selectedForm: prevState.selectedForm === form ? null : form, // Deselect if already selected
    }));
  };

  handleAddConfigClick = () => {
    this.setState({ openConfigModal: true });
    this.handleMenuClose();
  };

  handleCloseConfigModal = () => {
    this.setState({ openConfigModal: false });
  };

  render() {
    const { classes, allForms } = this.props;
    const { anchorEl, selectedForm, openConfigModal } = this.state;

    return (
      <>
        <Paper className={classes.paper}>
          <div
            className={classes.title}
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px" }}
          >
            <Typography>Forms</Typography>
            {selectedForm && (
              <IconButton onClick={this.handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
            )}

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.handleMenuClose}>
              <MenuItem onClick={this.handleAddConfigClick}>Add configurations</MenuItem>
            </Menu>
          </div>

          <div className={classes.page}>
            {allForms?.map((form, idx) => (
              <Typography
                key={form.formName}
                style={{
                  marginTop: 8,
                  cursor: "pointer",
                  padding: "10px",
                  border: selectedForm === form ? "solid 1px #bcbcbc" : "solid 1px transparent",
                  borderRadius: selectedForm === form ? "5px" : 0,
                }}
                onClick={() => this.handleFormClick(form)}
              >
                {idx + 1} {form.formName}
              </Typography>
            ))}
          </div>
        </Paper>

        <Dialog open={openConfigModal} onClose={this.handleCloseConfigModal}>
          <DialogTitle sx={{ alignText: "center" }}>Add Configurations</DialogTitle>
          <DialogContent>
            <Typography>Configurations for: {selectedForm?.formName}</Typography>
            {/* Add additional configuration fields here */}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseConfigModal} color="primary">
              Close
            </Button>
            <Button onClick={this.handleCloseConfigModal} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  mutation: state.accredation.mutation,
  fetchingAllForms: state.accredation.fetchingAllForms,
  fetchedAllForms: state.accredation.fetchedAllForms,
  allForms: state.accredation.allForms,
  errorAllForms: state.accredation.errorAllForms,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ journalize, fetchAllForms }, dispatch);
};

export default withModulesManager(
  injectIntl(withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(AllForms))))),
);
