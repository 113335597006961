import React, { Component } from "react";
import { injectIntl } from "react-intl";
import _debounce from "lodash/debounce";

import { Grid } from "@material-ui/core";
import { withTheme, withStyles } from "@material-ui/core/styles";

import { withModulesManager, TextInput, PublishedComponent, ControlledField } from "@openimis/fe-core";
import StatusPicker from "../pickers/StatusPicker";

const styles = (theme) => ({
  dialogTitle: theme.dialog.title,
  dialogContent: theme.dialog.content,
  form: {
    padding: 0,
  },
  item: {
    padding: theme.spacing(1),
  },
  paperDivider: theme.paper.divider,
});

class QualityAssuranceFilter extends Component {
  constructor(props) {
    super(props);
    // Use lodash debounce to delay the _handleInputChange function
    this.debouncedHandleInputChange = _debounce(this._handleInputChange, 300);
  }

  _handleInputChange = (key, value) => {
    let filterCondition;

    // Define keys expected to be integers or strings
    const intKeys = ["status"]; // Integer fields
    const stringKeys = ["hfName", "projectName"]; // String fields

    // Format value based on the expected type
    let formattedValue;
    if (intKeys.includes(key)) {
      formattedValue = parseInt(value, 10); // Convert to integer if it's an int field
    } else {
      formattedValue = value ? `"${value}"` : null; // Wrap in quotes for strings
    }

    // Apply "i_contains" only for specific string fields
    if (stringKeys.includes(key) && key !== "dataCollector" && key !== "status") {
      filterCondition = !!value ? `${key}_Icontains: ${formattedValue}` : null;
    } else {
      filterCondition = !!value ? `${key}: ${formattedValue}` : null;
    }

    this.props.onChangeFilters([
      {
        id: key,
        value: value,
        filter: filterCondition,
      },
    ]);
  };

  // Call the debounced function instead of _handleInputChange directly
  _onInputChange = (key, value) => {
    this.debouncedHandleInputChange(key, value);
  };
  render() {
    const { intl, classes, modulesManager, filters } = this.props;

    return (
      <Grid container className={classes.form}>
        <Grid item xs={4} className={classes.item}>
          <TextInput
            label="Quality Assurance Name"
            name="projectName"
            value={this.props.filters?.projectName?.value || ""}
            onChange={(v) => this._onInputChange("name", v)}
          />
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <PublishedComponent
            pubRef="location.HealthFacilityPicker"
            value={this.props.filter ? this.props.filters?.healthFacility?.name : null}
            onChange={(v) => this._onInputChange("healthFacility", v ? v.id : "")}
          />
        </Grid>
        <ControlledField
          module="accredation"
          id="accredation.dateCreated"
          field={
            <Grid item xs={4} className={classes.item}>
              <PublishedComponent
                pubRef="core.DatePicker"
                value={filters?.created?.value}
                module="accredation"
                label="project.createdDate"
                reset={false}
                onChange={(d) => this._onInputChange("createdDate", d ? new Date(d).toISOString() : null)}
              />
            </Grid>
          }
        />
        <Grid item xs={4} className={classes.item}>
          {/* <TextInput
            module="accreditation"
            label="QA.status"
            fullWidth
            name="fax"
            value={this._filterTextFieldValue("status")}
            onChange={(v) =>
              this.debouncedOnChangeFilter([
                {
                  id: "fax",
                  value: v,
                  filter: !!v ? `fax_Icontains: "${v}"` : null,
                },
              ])
            } */}
          {/* /> */}

          <StatusPicker onChange={(d) => this._onInputChange("status", d)} />
        </Grid>
      </Grid>
    );
  }
}

export default withModulesManager(injectIntl(withTheme(withStyles(styles)(QualityAssuranceFilter))));
