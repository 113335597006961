import React, { Fragment, Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { withModulesManager, journalize, coreConfirm, Searcher, formatDateFromISO } from "@openimis/fe-core";

import { formatMessageWithValues } from "@openimis/fe-core";
import { fetchQualityAssuranceProjects } from "../actions";
import QualityAssuranceFilter from "./QualityAssuranceFilter";

class QualityAssurancesSearcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmedAction: "",
    };

    this.rowIdentifier = this.rowIdentifier.bind(this);
    this.rowLocked = this.rowLocked.bind(this);
    this.rowDisabled = this.rowDisabled.bind(this);
    this.headers = this.headers.bind(this);
    this.sorts = this.sorts.bind(this);
    this.itemFormatters = this.itemFormatters.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingMutation && !this.props.submittingMutation && this.props.mutation) {
      this.props.journalize(this.props.mutation);
    } else if (prevProps.confirmed !== this.props.confirmed && this.props.confirmed && this.state.confirmedAction) {
      this.state.confirmedAction();
    }
  }

  rowIdentifier(r) {
    return r.uuid;
  }

  headers(filters) {
    return ["QA.name", "Hf.name", "Date Created"];
  }

  sorts(filters) {
    return [
      ["code", true],
      ["name", true],
      ["legalForm", true],
      ["level", true],
      ["careType", true],
      null,
      null,
      null,
      null,
      null,
      ["validityFrom", false],
      ["validityTo", false],
    ];
  }

  itemFormatters(filters) {
    return [
      (prj) => prj.name,
      (prj) => prj.healthFacility?.name,
      (prj) => formatDateFromISO(this.props.modulesManager, this.props.intl, prj.createdDate),
    ];
  }

  rowDisabled(selection, i) {
    return !!i.validityTo;
  }

  rowLocked(selection, hf) {
    return !!hf.clientMutationId;
  }

  render() {
    const {
      modulesManager,
      intl,
      fetchQualityAssuranceProjects,
      onDoubleClick,
      fetchedQualityAssuranceProjects,
      fetchingQualityAssuranceProjects,
      errorQualityAssuranceProjects,
      qualityAssuranceProjects,
    } = this.props;

    console.log("QA", qualityAssuranceProjects);

    const rowsPerPageOptions = modulesManager.getConf(
      "fe-location",
      "healthFacilityFilter.rowsPerPageOptions",
      [10, 20, 50, 100],
    );
    const defaultPageSize = modulesManager.getConf("fe-accredation", "healthFacilityFilter.defaultPageSize", 10);

    const count = 5;

    return (
      <Fragment>
        <Searcher
          module="accredation"
          rowsPerPageOptions={rowsPerPageOptions}
          defaultPageSize={defaultPageSize}
          fetch={fetchQualityAssuranceProjects}
          fetchingItems={fetchingQualityAssuranceProjects}
          fetchedItems={fetchedQualityAssuranceProjects}
          errorItems={errorQualityAssuranceProjects}
          cacheFiltersKey="qualityAssuranceProjectSearcher"
          items={qualityAssuranceProjects}
          rowIdentifier={this.rowIdentifier}
          rowLocked={this.rowLocked}
          itemsPageInfo={{ ...qualityAssuranceProjects?.pageInfo, totalCount: 10 }}
          FilterPane={QualityAssuranceFilter}
          tableTitle={formatMessageWithValues(intl, "accredation", "Quality Assurances Health Facilities", { count })}
          headers={this.headers}
          itemFormatters={this.itemFormatters}
          rowDisabled={this.rowDisabled}
          sorts={this.sorts}
          onDoubleClick={onDoubleClick}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  userHealthFacilityFullPath: state.loc.userHealthFacilityFullPath,
  submittingMutation: state.loc.submittingMutation,
  mutation: state.loc.mutation,
  confirmed: state.core.confirmed,
  fetchingQualityAssuranceProjects: state.accredation.fetchingQualityAssuranceProjects,
  fetchedQualityAssuranceProjects: state.accredation.fetchedQualityAssuranceProjects,
  qualityAssuranceProjects: state.accredation.qualityAssuranceProjects,
  errorQualityAssuranceProjects: state.accredation.errorQualityAssuranceProjects,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ coreConfirm, journalize, fetchQualityAssuranceProjects }, dispatch);
};

export default withModulesManager(injectIntl(connect(mapStateToProps, mapDispatchToProps)(QualityAssurancesSearcher)));
