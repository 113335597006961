import {
  parseData,
  dispatchMutationReq,
  dispatchMutationResp,
  dispatchMutationErr,
  pageInfo,
  formatServerError,
  formatGraphQLError,
} from "@openimis/fe-core";

function reducer(
  state = {
    fetchingPreAuthorization: false,
    errorPreAuthorization: null,
    fetchedPreAuthorization: false,
    preauthorizations: [],
    preAuthorizationPageInfo: { totalCount: 0 },
    preauthorization: {},
    submittingMutation: false,
    mutation: {},
  },
  action
) {
  switch (action.type) {
    case "PREAUTHORIZATION_MY_PREAUTHORIZATION_REQ":
      return {
        ...state,
        fetchingPreAuthorization: true,
        fetchedPreAuthorization: false,
        preauthorizations: null,
        preAuthorizationPageInfo: { totalCount: 0 },
        errorPreAuthorization: null,
      };
    case "PREAUTHORIZATION_MY_PREAUTHORIZATION_RESP":
      return {
        ...state,
        fetchingPreAuthorization: false,
        fetchedPreAuthorization: true,
        preauthorizations: parseData(action.payload.data.prauthorizations),
        preAuthorizationPageInfo: pageInfo(
          action.payload.data.prauthorizations
        ),
        errorPreAuthorization: formatGraphQLError(action.payload),
      };
    case "PREAUTHORIZATION_MY_PREAUTHORIZATION_ERR":
      return {
        ...state,
        fetching: false,
        error: formatServerError(action.payload),
      };

    case "PREAUTHORIZATION_MY_PREAUTHORIZATION_GET_PREAUTH_REQ":
      return {
        ...state,
        fetchingPreauthorization: true,
        fetchedPreathorization: false,
        preauthorization: null,
        errorPreauthorization: null,
      };
    case "PREAUTHORIZATION_MY_PREAUTHORIZATION_GET_PREAUTH_RESP":
      return {
        ...state,
        fetchingPreauthorization: false,
        fetchedPreathorization: true,
        preauthorization: action.payload.data.prauthorization,
        errorPreauthorization: formatGraphQLError(action.payload),
      };
    case "PREAUTHORIZATION_MY_PREAUTHORIZATION_GET_PREAUTH_ERR":
      return {
        ...state,
        fetchingPreauthorization: false,
        errorPreauthorization: formatServerError(action.payload),
      };

    case "PREAUTHORIZATION_CREATE_REQ":
      return dispatchMutationReq(state, action);
    case "PREAUTHORIZATION_CREATE_ERR":
      return dispatchMutationErr(state, action);
    case "PREAUTHORIZATION_CREATE_RESP":
      return dispatchMutationResp(state, "createPrauthorization", action);
    case "PREAUHORIZATION_APPROVE_REQ":
      return dispatchMutationReq(state, action);
    case "PREAUHORIZATION_APPROVE_ERR":
      return dispatchMutationErr(state, action);
    case "PREAUHORIZATION_APPROVE_RESP":
      return dispatchMutationResp(state, "approvedPrauthorization", action);
    case "PREAUHORIZATION_REJECT_REQ":
      return dispatchMutationReq(state, action);
    case "PREAUHORIZATION_REJECT_ERR":
      return dispatchMutationErr(state, action);
    case "PREAUHORIZATION_REJECT_RESP":
      return dispatchMutationResp(state, "rejectedPrauthorization", action);
    default:
      return state;
  }
}

export default reducer;
