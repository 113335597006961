import React, { Component } from "react";
import { Box, Fab } from "@material-ui/core";
import ProjectsMasterPanel from "../components/ProjectsMasterPanel";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { bindActionCreators } from "redux";
import {
  withHistory,
  historyPush,
  formatMessage,
  Helmet,
  clearCurrentPaginationPage,
  withModulesManager,
} from "@openimis/fe-core";

import ProjectForm from "../components/ProjectForm";
import { fetchAccredation } from "../actions";
import isEqual from "lodash/isEqual";
import { ACCREDITATION } from "../constants";

const styles = (theme) => ({
  page: theme.page,
  fab: theme.fab,
});

class AccreditationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edited: {},
    };
  }

  componentDidMount() {
    const projectUuid = this.props?.match?.params?.projectUuid;
    if (projectUuid) {
      // If projectUuid exists, fetch the accreditation data
      this.props.fetchAccredation(projectUuid);
    }
  }

  componentDidUpdate(prevProps) {
    const { accreditation } = this.props;
    // Check if accreditation data has changed and is different from the current state
    if (accreditation && !isEqual(accreditation, prevProps.accreditation)) {
      this.setState({
        edited: { ...accreditation },
      });
    }
  }

  handleChange = (name, value) => {
    this.setState((prevState) => ({
      edited: {
        ...prevState.edited,
        [name]: value,
      },
    }));
  };

  onAdd = () => {
    const { modulesManager, history } = this.props;
    if (modulesManager && history) {
      historyPush(modulesManager, history, "accreditation.route.details");
    }
  };
  render() {
    const {
      classes,
      rights,
      modulesManager,
      history,
      intl,
      module,
      clearCurrentPaginationPage,
      accreditation,
      fetchingAccreditation,
      fetchedAccreditation,
      errorAccreditation,
    } = this.props;
    const projectUuid = this.props?.match?.params?.projectUuid;
    const projectId = accreditation ? accreditation[0].id : null;

    console.log("acre", projectId);

    return (
      <div className={""}>
        <Helmet title={formatMessage(intl, "accredation", "projects.page.title")} />
        {(!projectUuid || fetchedAccreditation) && (
          <ProjectForm
            edited={this.state.edited}
            updateAttribute={this.handleChange}
            projectUuid={projectUuid}
            projectId={projectId}
            type={ACCREDITATION}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  module: state.core?.savedPagination?.module,
  accreditation: state.accredation.accreditation,
  fetchingAccreditation: state.accredation.fetchingAccreditation,
  fetchedAccreditation: state.accredation.fetchedAccreditation,
  errorAccreditation: state.accredation.errorAccreditation,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ clearCurrentPaginationPage, fetchAccredation }, dispatch);

export default injectIntl(
  withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(AccreditationPage)))),
);
