import {
  graphql,
  formatQuery,
  formatPageQueryWithCount,
  decodeId,
  formatMutation,
} from "@openimis/fe-core";

export function fetchMyPreauthorization(mm, prms) {
  const payload = formatPageQueryWithCount("prauthorizations", prms, [
    "praStatus",
    "uuid",
    "validityFrom",
    "insuree" + mm.getProjection("insuree.InsureePicker.projection"),
    "refHf{id uuid name code}",
    "recHf{id uuid name code}",
    "icd{id, name, code}",
    "service{id, uuid, name, code}",
    "explanation",
    "reasonForReject",
  ]);
  return graphql(payload, "PREAUTHORIZATION_MY_PREAUTHORIZATION");
}

export function savePREAUTHS(Prauthorization, clientMutationLabel) {
  let PrauthorizationGQL = ` 
    insureeId: ${decodeId(Prauthorization.insureeId.id)}
    refHf: "${Prauthorization["refHf"]["uuid"]}"
    recHf: "${Prauthorization["recHf"]["uuid"]}"
    icd: "${Prauthorization["icd"]["name"]}"
    service: "${Prauthorization["service"]["name"]}"
    praStatus:"${Prauthorization["praStatus"]}"
    explanation:"${Prauthorization["explanation"] || ""}"
    `;
  let mutation = formatMutation(
    "createPrauthorization",
    PrauthorizationGQL,
    clientMutationLabel
  );
  var requestedDateTime = new Date();
  return graphql(mutation.payload, "PREAUTHORIZATION_CREATE", {
    clientMutationId: mutation.clientMutationId,
    clientMutationLabel,
    requestedDateTime,
  });
}

export function approvePreauthorization(Prauthorization, clientMutationLabel) {
  let PrauthorizationGQL = ` 
  uuid:"${Prauthorization.uuid}"
  insureeId: ${decodeId(Prauthorization.insuree.id)}
  refHf: "${Prauthorization["refHf"]["uuid"]}"
  recHf: "${Prauthorization["recHf"]["uuid"]}"
  icd: "${Prauthorization["icd"]["name"]}"
  service: "${Prauthorization["service"]["name"]}"
  explanation:"${Prauthorization["explanation"]}"
  praStatus:"A"
  `;
  let mutation = formatMutation(
    "approvedPrauthorization",
    PrauthorizationGQL,
    clientMutationLabel
  );
  var requestedDateTime = new Date();
  return graphql(mutation.payload, "PREAUHORIZATION_APPROVE", {
    clientMutationId: mutation.clientMutationId,
    clientMutationLabel,
    requestedDateTime,
  });
}

export function rejectPreauthorization(Prauthorization, clientMutationLabel) {
  let PrauthorizationGQL = `
  uuid:"${Prauthorization.uuid}"
  insureeId: ${decodeId(Prauthorization.insuree.id)}
  refHf: "${Prauthorization["refHf"]["uuid"]}"
  recHf: "${Prauthorization["recHf"]["uuid"]}"
  icd: "${Prauthorization["icd"]["name"]}"
  service: "${Prauthorization["service"]["name"]}"
  explanation:"${Prauthorization["explanation"]}"
  reasonForReject:"${Prauthorization["reasonForReject"]}"
  praStatus:"R"
  `;
  let mutation = formatMutation(
    "rejectedPrauthorization",
    PrauthorizationGQL,
    clientMutationLabel
  );
  var requestedDateTime = new Date();
  Prauthorization.clientMutationId = mutation.clientMutationId;
  return graphql(mutation.payload, "PREAUHORIZATION_REJECT", {
    clientMutationId: mutation.clientMutationId,
    clientMutationLabel,
    requestedDateTime,
  });
}
