import { Grid } from "@material-ui/core";

import { PublishedComponent } from "@openimis/fe-core";

import React from "react";

 

const PaymentContributionReport = (props) => {

  const { values, setValues } = props;

 

 

  return (

    <Grid container direction="column" spacing={1}>

      <Grid item>

        <PublishedComponent

           pubRef="core.DatePicker"

           module="contract"

           label="dateValidFrom"

           value={values.date_valid_from}

           onChange={(date_valid_from) => setValues({ ...values, date_valid_from})}

        />

      </Grid>

      <Grid item>

        <PublishedComponent

        pubRef="core.DatePicker"

        module="contract"

        label="dateValidTo"

        value={values.date_valid_to}

        onChange={(date_valid_to) => setValues({ ...values, date_valid_to})}

        />

      </Grid>

      <Grid item>

        <PublishedComponent  

        pubRef="policyHolder.PolicyHolderPicker"

        module="contract"

        required

        label="Policy  Holder"

        value={values.policyHolder}

        onChange={(policyHolder) =>  setValues({ ...values, policyHolder})}

        />

      </Grid>

    </Grid>

  );

};

 

 

export default  PaymentContributionReport;