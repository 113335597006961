import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
  Fab,
  Grid,
  Paper,
  Divider,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import {
  TextInput,
  FormattedMessage,
  formatMessage,
  ControlledField,
  PublishedComponent,
  historyPush,
  journalize,
} from "@openimis/fe-core";
import {
  savePREAUTHS,
  fetchMyPreauthorization,
  approvePreauthorization,
  rejectPreauthorization,
} from "../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Save, KeyboardBackspace } from "@material-ui/icons";
import { injectIntl } from "react-intl";
import PreauthorizationConfirmation from "../components/PreauthorizationConfirmation";

import { RIGHT_PRAUTHORIZATION_EDIT } from "../constants";

const styles = (theme) => ({
  paper: theme.paper.paper,
  tableTitle: theme.table.title,
  item: theme.paper.item,
  fullHeight: {
    height: "100%",
  },
});

class AddPrauth extends Component {
  state = {
    edited: {
      praStatus: "E",
    },
    preauth_uuid: null,
    open: false,
    action: null,
    title: "",
    disablePage: false,
    showPopUp: false,
  };

  async componentDidMount() {
    // get the url
    const url = window.location.href;
    const urlParts = url.split("/");
    //collect the uuid from the url
    const preauth_uuid = urlParts[urlParts.length - 1];
    if (preauth_uuid) {
      // Fetch preauthorizations data
      await this.props.fetchMyPreauthorization(this.props.modulesManager);

      // Now that data is fetched and available in this.props.preauthorizations
      //find the item with the collected uuid
      const preauthorization = this.props.preauthorizations.find(
        (preath) => preath.uuid === preauth_uuid
      );

      if (preauthorization) {
        this.setState((prevState) => ({
          ...prevState,
          edited: preauthorization,
          preauth_uuid: preauth_uuid,
        }));
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
      this.setState((prevState) => ({
        ...prevProps,
        showPopUp: true,
      }));
    }
  }

  //open confirm dialog
  handleOpen = () => {
    this.setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  //close confirm dialog
  handleClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  handleDisablePage = () => {
    this.setState((prevState) => ({
      ...prevState,
      disablePage: true,
    }));
  };
  save = (e) => {
    this.props.savePREAUTHS(
      this.state.edited,
      `Creating ${this.state.edited.insureeId.lastName},`
    );
    this.handleDisablePage();
  };

  updateAttribute = (k, v) => {
    this.setState((state) => ({
      edited: { ...state.edited, [k]: v },
    }));
  };

  //send mutation for approving a preauthorization
  mutateApproved = () => {
    this.props.approvePreauthorization(
      this.state.edited,
      `Approved ${this.state.edited.insuree.lastName}`
    );
    this.handleDisablePage();
  };

  //send mutation for reject a preauthorization
  mutateReject = () => {
    this.props.rejectPreauthorization(
      this.state.edited,
      `Rejected ${this.state.edited.insuree.lastName}`
    );
    this.handleDisablePage();
  };

  //function to decide if to pass approve or reject mutation to confirmation dialog
  handleAction = (action) => {
    if (action === "approve") {
      this.setState((prev) => ({
        ...prev,
        action: this.mutateApproved,
        open: true,
        title: "Approve",
      }));
    } else if (action === "reject") {
      this.setState((prev) => ({
        ...prev,
        action: this.mutateReject,
        open: true,
        title: "Reject",
      }));
    }
  };

  //reroute to main page
  pushToMainPage = () => {
    historyPush(
      this.props.modulesManager,
      this.props.history,
      "preauthorization.route.preauthorization"
    );
  };

  render() {
    const { classes, reset, intl, rights, mutation } = this.props;
    const { edited } = this.state;
    return (
      <div className={classes.page} style={{ marginTop: "1.5em" }}>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container className={classes.tableTitle}>
                <Grid item xs={3} className={classes.tableTitle}>
                  <Box display="flex" alignItems="center">
                    <IconButton onClick={this.pushToMainPage}>
                      <KeyboardBackspace />
                    </IconButton>
                    <Typography>
                      <FormattedMessage
                        module="preauthorization"
                        id={
                          this.state.preauth_uuid
                            ? "menu.editPreauthorization"
                            : "menu.addPreauthorization"
                        }
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Grid container className={classes.item}>
                <ControlledField
                  module="PreAuth"
                  id="PreAuth.insuree"
                  field={
                    <Grid item xs={3} className={classes.item}>
                      <PublishedComponent
                        pubRef="insuree.InsureePicker"
                        name="Insuree"
                        value={edited?.insuree}
                        reset={reset}
                        onChange={(v, s) =>
                          this.updateAttribute("insureeId", v)
                        }
                        required
                        readOnly={
                          edited.praStatus != "E" || this.state.disablePage
                        }
                      />
                    </Grid>
                  }
                />
                <Grid item xs={3} className={classes.item}>
                  <PublishedComponent
                    pubRef="location.HealthFacilityPicker"
                    label={formatMessage(
                      intl,
                      "preauthorization",
                      "preauthorization.ReceivingHF"
                    )}
                    value={edited?.recHf}
                    reset={reset}
                    onChange={(v, s) => this.updateAttribute("recHf", v)}
                    required
                    readOnly={edited.praStatus != "E" || this.state.disablePage}
                  />
                </Grid>
                <Grid item xs={3} className={classes.item}>
                  <PublishedComponent
                    pubRef="location.HealthFacilityPicker"
                    name="rec_hf"
                    label={formatMessage(
                      intl,
                      "preauthorization",
                      "preauthorization.RefferingHF"
                    )}
                    value={edited?.refHf}
                    reset={reset}
                    onChange={(v, s) => this.updateAttribute("refHf", v)}
                    required
                    readOnly={edited.praStatus != "E" || this.state.disablePage}
                  />
                </Grid>
                <Grid item xs={3} className={classes.item}>
                  <PublishedComponent
                    pubRef="medical.DiagnosisPicker"
                    name="mainDiagnosis"
                    value={edited?.icd}
                    reset={reset}
                    onChange={(v, s) => this.updateAttribute("icd", v)}
                    required
                    readOnly={edited.praStatus != "E" || this.state.disablePage}
                  />
                </Grid>
                <Grid item xs={3} className={classes.item}>
                  <PublishedComponent
                    pubRef="medical.ServicePicker"
                    name="Service"
                    value={edited?.service}
                    reset={reset}
                    onChange={(v, s) => this.updateAttribute("service", v)}
                    required
                    readOnly={edited.praStatus != "E" || this.state.disablePage}
                  />
                </Grid>
                <Grid item xs={3} className={classes.item}>
                  <TextInput
                    value={
                      edited.praStatus
                        ? formatMessage(
                            this.props.intl,
                            "preauthorization",
                            `preauthorization.Status.${edited.praStatus}`
                          )
                        : ""
                    }
                    label={"preauthorization.Status"}
                    readOnly
                  />
                </Grid>
                <Grid item xs={6} className={classes.item}>
                  <TextInput
                    value={edited.explanation}
                    label={formatMessage(
                      this.props.intl,
                      "preauthorization",
                      "preauthorization.Explanation"
                    )}
                    readOnly={edited.praStatus != "E" || this.state.disablePage}
                    onChange={(v) => this.updateAttribute("explanation", v)}
                  />
                </Grid>
                {edited.praStatus != "A" && this.state.preauth_uuid && (
                  <Grid item xs={6} className={classes.item}>
                    <TextInput
                      value={edited.reasonForReject}
                      label={formatMessage(
                        this.props.intl,
                        "preauthorization",
                        "preauthorization.ReasonForRejection"
                      )}
                      readOnly={
                        edited.praStatus != "E" || this.state.disablePage
                      }
                      onChange={(v) =>
                        this.updateAttribute("reasonForReject", v)
                      }
                    />
                  </Grid>
                )}
              </Grid>

              {/* Mutation comfirmation dialog */}
              <PreauthorizationConfirmation
                handleOpen={this.handleOpen}
                handleClose={this.handleClose}
                title={this.state.title}
                open={this.state.open}
                action={this.state.action}
              />

              <div
                style={{
                  display: "flex",
                  gap: "4em",
                  alignItems: "center",
                  justifyContent: "end",
                  padding: "2em",
                }}
              >
                {this.state.preauth_uuid && (
                  <div
                    style={{
                      display: "flex",
                      gap: "2.5em",
                      justifyContent: "end",
                      padding: "2em",
                    }}
                  >
                    <button
                      style={{
                        padding: "8px 16px",
                        width: "90px",
                        background: `${
                          edited.praStatus != "E" || this.state.disablePage
                            ? "grey"
                            : "#006273"
                        }`,
                        border: "solid 2px transparent",
                        borderRadius: "5px",
                        cursor: `${edited.praStatus != "E" ? "" : "pointer"}`,
                        color: "white",
                      }}
                      onClick={() => this.handleAction("approve")}
                      disabled={
                        edited.praStatus != "E" || this.state.disablePage
                      }
                    >
                      Approve
                    </button>

                    <button
                      style={{
                        padding: "5px 16px",
                        width: "90px",
                        background: `${
                          edited.praStatus != "E" || this.state.disablePage
                            ? "grey"
                            : "red"
                        }`,
                        border: `${
                          edited.praStatus != "E" ? "grey" : "#006273"
                        }`,
                        borderRadius: "5px",
                        cursor: `${edited.praStatus != "E" ? "" : "pointer"}`,
                        color: "white",
                      }}
                      onClick={() => this.handleAction("reject")}
                      disabled={
                        edited.praStatus != "E" || this.state.disablePage
                      }
                    >
                      Reject
                    </button>
                  </div>
                )}
                {edited.praStatus == "E" && (
                  <Fab
                    color="primary"
                    disabled={this.state.disablePage}
                    onClick={this.save}
                  >
                    <Save />
                  </Fab>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>

        {this.state.showPopUp && mutation?.clientMutationId && (
          <PublishedComponent
            pubRef="info.notification"
            mutation={mutation}
            errorMessage={"Failed to create preauthorization"}
          />
        )}
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights:
    !!state.core && !!state.core.user && !!state.core.user.i_user
      ? state.core.user.i_user.rights
      : [],
  preauthorizations: state.preauthorization.preauthorizations,
  mutation: state.preauthorization.mutation,
  submittingMutation: state.preauthorization.submittingMutation,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      savePREAUTHS,
      fetchMyPreauthorization,
      rejectPreauthorization,
      approvePreauthorization,
      journalize,
    },
    dispatch
  );
};

export default withTheme(
  injectIntl(
    withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddPrauth))
  )
);
