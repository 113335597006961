import React, { Fragment, Component, useState } from "react";
import { Grid, Paper, Typography, Button, Box } from "@material-ui/core";
import { PublishedComponent } from "@openimis/fe-core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withTheme, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { withModulesManager, journalize, coreConfirm, Searcher } from "@openimis/fe-core";
import { createODKUser } from "../actions";

const styles = (theme) => ({
  page: theme.page,
  paper: theme.paper.paper,
  title: theme.paper.title,
});

function UsersPanel(props) {
  const { classes } = props;
  const [user, setUser] = useState(null);

  const handleAssignUser = () => {
    if (user) {
      props.createODKUser(user);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>Assign User</Typography>
      <div className={classes.page}>
        <Grid container>
          <Grid xs={6}>
            <PublishedComponent pubRef="admin.UserPicker" onChange={(v) => setUser(v)} />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="end">
          <Button variant="contained" color="primary" onClick={handleAssignUser}>
            Assign User
          </Button>
        </Box>
      </div>
    </Paper>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ journalize, createODKUser }, dispatch);
};
export default withModulesManager(
  connect(null, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(UsersPanel)))),
);
