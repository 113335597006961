import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { STATUS } from "../constants";

class StatusPicker extends Component {
  render() {
    return <ConstantBasedPicker module="accredation" label="Status" constants={STATUS} {...this.props} />;
  }
}

export default StatusPicker;
