import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { withTheme, withStyles } from "@material-ui/core/styles";
import { withModulesManager, formatMessage } from "@openimis/fe-core";
import { fetchAllVillages, clearLocations } from "../actions.js";
import { locationLabel } from "../utils";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const styles = (theme) => ({
  textField: {
    width: "100%",
  },
});

function VillagePicker({ fetchAllVillages, clearLocations, allVillages, onChange, value, label }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (open && !allVillages) {
      setLoading(true);
      fetchAllVillages().finally(() => setLoading(false));
    }

    return () => {
      if (!open) {
        clearLocations(); // Clear locations when closing the dropdown
      }
    };
  }, [open, fetchAllVillages, clearLocations, allVillages]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event) => {
    const selectedVillageId = event.target.value;
    onChange(selectedVillageId); // Pass the selected village ID
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={handleChange} onOpen={handleOpen} onClose={handleClose} fullWidth displayEmpty>
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={20} />
          </MenuItem>
        ) : (
          allVillages?.map((village) => (
            <MenuItem key={village.id} value={village.id}>
              {village.code} {village.name}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
}

const mapStateToProps = (state) => ({
  districts: state.loc.userL1s,
  userHealthFacilityFullPath: state.loc.userHealthFacilityFullPath,
  allVillages: state.loc.allVillages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAllVillages,
      clearLocations,
    },
    dispatch,
  );

export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(VillagePicker)))),
);
