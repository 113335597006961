import React, { Component, Fragment } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import {
  formatMessage,
  withModulesManager,
  PublishedComponent,
  TextInput,
} from "@openimis/fe-core";
import PreauthorizationStatusPicker from "../picker/PreauthorizationStatusPicker";

const styles = (theme) => ({
  dialogTitle: theme.dialog.title,
  dialogContent: theme.dialog.content,
  form: {
    padding: 0,
  },
  item: {
    padding: theme.spacing(1),
  },
  paperDivider: theme.paper.divider,
});

class Details extends Component {
  render() {
    const { intl, classes, filters, handleFilter } = this.props;
    return (
      <main style={{ background: "#dbeef0" }}>
        <Grid container className={classes.form}>
          <div
            style={{
              background: "#b7d4d8",
              width: "100%",
              paddingLeft: ".8em",
            }}
          >
            <h2 style={{ fontWeight: "600" }}>Search Criteria</h2>
          </div>

          <Grid item xs={3} className={classes.item}>
            <TextInput
              module="preauthorization"
              label="preauthorization.insureeCHFID"
              name="chfId"
              value={filters["chfId"]}
              onChange={(v) => handleFilter("chfId", v)}
            />
          </Grid>

          <Grid item xs={3} className={classes.item}>
            <PublishedComponent
              label={formatMessage(
                intl,
                "preauthorization",
                "preauthorization.RefferingHF"
              )}
              pubRef="location.HealthFacilityPicker"
              value={filters["refHf"]}
              onChange={(v) => handleFilter("refHf", v)}
            />
          </Grid>

          <Grid item xs={3} className={classes.item}>
            <PublishedComponent
              label={formatMessage(
                intl,
                "preauthorization",
                "preauthorization.ReceivingHF"
              )}
              pubRef="location.HealthFacilityPicker"
              value={filters["recHf"]}
              onChange={(v) => handleFilter("recHf", v)}
            />
          </Grid>

          <Grid item xs={3} className={classes.item}>
            <PublishedComponent
              pubRef="medical.ServicePicker"
              value={filters.service ? filters["service"] : null}
              name="medicalService"
              label={formatMessage(intl, "claim", "medicalService")}
              onChange={(v) => handleFilter("service", v)}
            />
          </Grid>
          <Grid item xs={3} className={classes.item}>
            <PublishedComponent
              pubRef="medical.DiagnosisPicker"
              name="mainDiagnosis"
              label={formatMessage(intl, "claim", "mainDiagnosis")}
              value={filters["icd"] ? filters["icd"] : null}
              onChange={(v) => handleFilter("icd", v)}
            />
          </Grid>
          <Grid item xs={3} className={classes.item}>
            <PreauthorizationStatusPicker
              value={filters["praStatus"] ? filters["praStatus"] : ""}
              onChange={(v) => handleFilter("praStatus", v)}
            />
          </Grid>
        </Grid>
      </main>
    );
  }
}

class PreauthorizationFilter extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.page}>
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          style={{ width: "100%" }}
        >
          <Details
            {...this.props}
            handleFilter={this.props.handleFilter}
            filters={this.props.filters}
          />
        </form>
      </div>
    );
  }
}

export default withModulesManager(
  injectIntl(withTheme(withStyles(styles)(PreauthorizationFilter)))
);
