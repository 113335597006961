import React, { Fragment, Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { withModulesManager, journalize, coreConfirm, Searcher, formatMessage } from "@openimis/fe-core";
import AccreditationFilter from "./AccreditationFilter";
import { formatMessageWithValues, formatDateFromISO, withHistory, historyPush } from "@openimis/fe-core";
import { fetchHfAccredations } from "../actions";

class AccreditationSearcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmedAction: "",
    };

    this.rowIdentifier = this.rowIdentifier.bind(this);
    this.rowLocked = this.rowLocked.bind(this);
    this.rowDisabled = this.rowDisabled.bind(this);
    this.headers = this.headers.bind(this);
    this.sorts = this.sorts.bind(this);
    this.itemFormatters = this.itemFormatters.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingMutation && !this.props.submittingMutation && this.props.mutation) {
      this.props.journalize(this.props.mutation);
    } else if (prevProps.confirmed !== this.props.confirmed && this.props.confirmed && this.state.confirmedAction) {
      this.state.confirmedAction();
    }
  }

  rowIdentifier(r) {
    return r.uuid;
  }

  headers(filters) {
    return ["accreditation.date", "accreditation.collector", "accreditation.state"];
  }

  sorts(filters) {
    return [
      ["code", true],
      ["name", true],
      ["legalForm", true],
      ["level", true],
      ["careType", true],
      null,
      null,
      null,
      null,
      null,
      ["validityFrom", false],
      ["validityTo", false],
    ];
  }

  itemFormatters(filters) {
    return [
      (acc) => formatDateFromISO(this.props.modulesManager, this.props.intl, acc.created),
      (acc) => "",
      (acc) => formatMessage(this.props.intl, "accredation", `state.${acc.state}`),
    ];
  }

  rowDisabled(selection, i) {
    return !!i.validityTo;
  }

  rowLocked(selection, hf) {
    return !!hf.clientMutationId;
  }

  onDoubleClick = (acc) => {
    const { modulesManager, history } = this.props;
    if (modulesManager && history) {
      historyPush(modulesManager, history, "accreditation.route.details", [acc.uuid]);
    }
  };

  fetchAccredationsDetails = () => {
    const { projectId } = this.props;
    if (projectId) {
      this.props.fetchHfAccredations(projectId);
    }
  };
  render() {
    const {
      modulesManager,
      intl,
      healthFacilitiesPageInfo = {
        endCursor: "YXJyYXljb25uZWN0aW9uOjM=",
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: "YXJyYXljb25uZWN0aW9uOjA=",
        totalCount: 5,
      },
      accreditationDetails,
      fetchedAccreditationDetails,
      fetchingAccreditationDetails,
      errorAccreditationDetails,
      count = 5,
    } = this.props;

    console.log("AccreD", accreditationDetails);

    const rowsPerPageOptions = modulesManager.getConf(
      "fe-accredation",
      "healthFacilityFilter.rowsPerPageOptions",
      [10, 20, 50, 100],
    );
    const defaultPageSize = modulesManager.getConf("fe-accredation", "healthFacilityFilter.defaultPageSize", 10);

    return (
      <Fragment>
        <Searcher
          module="accredation"
          rowsPerPageOptions={rowsPerPageOptions}
          defaultPageSize={defaultPageSize}
          fetch={this.fetchAccredationsDetails}
          fetchingItems={fetchingAccreditationDetails}
          fetchedItems={fetchedAccreditationDetails}
          errorItems={errorAccreditationDetails}
          cacheFiltersKey="accredationSearcher"
          items={accreditationDetails}
          rowIdentifier={this.rowIdentifier}
          rowLocked={this.rowLocked}
          itemsPageInfo={healthFacilitiesPageInfo}
          FilterPane={AccreditationFilter}
          tableTitle={formatMessageWithValues(intl, "accredation", "projectsSummaries", { count })}
          headers={this.headers}
          itemFormatters={this.itemFormatters}
          rowDisabled={this.rowDisabled}
          sorts={this.sorts}
          onDoubleClick={this.onDoubleClick}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  userHealthFacilityFullPath: state.loc.userHealthFacilityFullPath,
  submittingMutation: state.loc.submittingMutation,
  mutation: state.loc.mutation,
  confirmed: state.core.confirmed,
  accreditationDetails: state.accredation.accreditationDetails,
  fetchedAccreditationDetails: state.accredation.fetchedAccreditationDetails,
  fetchingAccreditationDetails: state.accredation.fetchingAccreditationDetails,
  errorAccreditationDetails: state.accredation.errorAccreditationDetails,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ coreConfirm, journalize, fetchHfAccredations }, dispatch);
};

export default withModulesManager(
  injectIntl(withHistory(connect(mapStateToProps, mapDispatchToProps)(AccreditationSearcher))),
);
