import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles, withTheme, Paper, Typography, FormControlLabel, Checkbox, Box, Button } from "@material-ui/core";
import { fetchForms, deployForms } from "../actions"; // Assuming this action exists

const styles = (theme) => ({
  paper: theme.paper.paper,
  title: theme.paper.title,
  page: theme.page,
});

class FormsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formToPublish: [], // Initialize the state to hold selected form UUIDs
    };
  }

  componentDidMount() {
    const { formType } = this.props;
    if (formType) {
      this.props.fetchForms(formType);
    }
    // Fetch the forms when the component mounts
  }

  handleCheckboxChange = (uuid) => {
    this.setState((prevState) => {
      const { formToPublish } = prevState;

      // Check if the UUID is already in the array
      if (formToPublish.includes(uuid)) {
        // Remove it if it's already there
        return { formToPublish: formToPublish.filter((id) => id !== uuid) };
      } else {
        // Add it if it's not there
        return { formToPublish: [...formToPublish, uuid] };
      }
    });
  };

  handleDeployForms = () => {
    const { uuid, deployForms } = this.props;
    const { formToPublish } = this.state;
    deployForms(formToPublish, uuid, "Deployed Forms");
  };

  isDeployForms = () => !this.state.formToPublish.length || !this.props.uuid;

  render() {
    const { classes, forms, accDetailUuid } = this.props;
    const { formToPublish } = this.state;

    return (
      <div>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>Forms List</Typography>
          <div className={classes.page}>
            {forms?.map((form, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    color="primary"
                    value={form.uuid}
                    onChange={() => this.handleCheckboxChange(form.uuid)}
                    checked={formToPublish.includes(form.uuid)} // Check if the form UUID is selected
                  />
                }
                label={form.formName}
              />
            ))}
            <Box display={"flex"} justifyContent={"end"}>
              <Button
                variant={"contained"}
                onClick={this.handleDeployForms}
                color="primary"
                disabled={this.isDeployForms()}
              >
                Publish Forms
              </Button>
            </Box>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forms: state.accredation.forms, // Assuming you store fetched forms in state.accredation.forms
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchForms, deployForms }, dispatch);

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FormsList)));
