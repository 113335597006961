import React, { Component } from "react";
import FormsPanel from "../components/FormsPanel";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { uploadOdkForm } from "../actions";
import { journalize } from "@openimis/fe-core";
import AllForms from "../components/AllForms";

class Formspage extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
    }
  }

  handleUpload = (formData) => {
    this.props.uploadOdkForm(formData);
  };
  render() {
    return (
      <div>
        <FormsPanel uploadForm={this.handleUpload} />
        <AllForms />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  userHealthFacilityFullPath: state.loc.userHealthFacilityFullPath,
  submittingMutation: state.loc.submittingMutation,
  mutation: state.loc.mutation,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ journalize, uploadOdkForm }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Formspage);
