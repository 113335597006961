import { Grid } from "@material-ui/core";
import { PublishedComponent, TextInput } from "@openimis/fe-core";
import React from "react";

const Pacode = (props) => {
  const { values, setValues } = props;

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <PublishedComponent
          pubRef="core.DatePicker"
          value={values.date_from}
          module="prauthorization"
          required
          label="PA.dateFrom"
          onChange={(date_from) => setValues({ ...values, date_from })}
        />
      </Grid>
      <Grid item>
        <PublishedComponent
          pubRef="core.DatePicker"
          value={values.date_to}
          module="prauthorization"
          required
          label="PA.dateTo"
          onChange={(date_to) => setValues({ ...values, date_to })}
        />
      </Grid>
      <Grid item>
        <PublishedComponent
          pubRef="claim.PStatusPicker"
          value={values.pra_status}
          required
          label="PA.pra_status"
          onChange={(pra_status) => setValues({ ...values, pra_status })}
        />
      </Grid>
    </Grid>
  );
};

export default Pacode;
