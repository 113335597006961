import React, { Fragment, Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { withModulesManager, journalize, coreConfirm, Searcher, formatMessage } from "@openimis/fe-core";
import ProjectsFilter from "./ProjectsFilter";
import { formatMessageWithValues, formatDateFromISO } from "@openimis/fe-core";
import { fetchAccreditationProjects } from "../actions";

class ProjectsSearcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmedAction: "",
    };

    this.rowIdentifier = this.rowIdentifier.bind(this);
    this.rowLocked = this.rowLocked.bind(this);
    this.rowDisabled = this.rowDisabled.bind(this);
    this.headers = this.headers.bind(this);
    this.sorts = this.sorts.bind(this);
    this.itemFormatters = this.itemFormatters.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingMutation && !this.props.submittingMutation && this.props.mutation) {
      this.props.journalize(this.props.mutation);
    } else if (prevProps.confirmed !== this.props.confirmed && this.props.confirmed && this.state.confirmedAction) {
      this.state.confirmedAction();
    }
  }

  rowIdentifier(r) {
    return r.uuid;
  }

  headers(filters) {
    return ["accreditation.parent.name", "project.Hf", "project.createdDate", "project.status"];
  }

  sorts(filters) {
    return [
      ["code", true],
      ["name", true],
      ["legalForm", true],
      ["level", true],
      ["careType", true],
      null,
      null,
      null,
      null,
      null,
      ["validityFrom", false],
      ["validityTo", false],
    ];
  }

  itemFormatters(filters) {
    return [
      (prj) => prj.projectName,
      (prj) => prj.healthFacility.name,
      (prj) => formatDateFromISO(this.props.modulesManager, this.props.intl, prj.createdDate),
      (prj) => formatMessage(this.props.intl, "accredation", `project.status.${prj.status}`),
    ];
  }

  rowDisabled(selection, i) {
    return !!i.validityTo;
  }

  rowLocked(selection, hf) {
    return !!hf.clientMutationId;
  }

  render() {
    const {
      modulesManager,
      intl,
      healthFacilitiesPageInfo = {
        endCursor: "YXJyYXljb25uZWN0aW9uOjM=",
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: "YXJyYXljb25uZWN0aW9uOjA=",
        totalCount: 5,
      },
      onDoubleClick,
      accreditations,
      fetchedAccreditationProjects,
      fetchingAccreditationProjects,
      errorAccreditationProjects,
    } = this.props;

    const rowsPerPageOptions = modulesManager.getConf(
      "fe-location",
      "healthFacilityFilter.rowsPerPageOptions",
      [10, 20, 50, 100],
    );
    const defaultPageSize = modulesManager.getConf("fe-accredation", "healthFacilityFilter.defaultPageSize", 10);

    const count = 5;

    return (
      <Fragment>
        <Searcher
          module="accredation"
          rowsPerPageOptions={rowsPerPageOptions}
          defaultPageSize={defaultPageSize}
          fetch={this.props.fetchAccreditationProjects}
          fetchingItems={fetchingAccreditationProjects}
          fetchedItems={fetchedAccreditationProjects}
          errorItems={errorAccreditationProjects}
          cacheFiltersKey="accredationProjectSearcher"
          items={accreditations}
          rowIdentifier={this.rowIdentifier}
          rowLocked={this.rowLocked}
          itemsPageInfo={healthFacilitiesPageInfo}
          FilterPane={ProjectsFilter}
          tableTitle={formatMessageWithValues(intl, "accredation", "projectsSummaries", { count })}
          headers={this.headers}
          itemFormatters={this.itemFormatters}
          rowDisabled={this.rowDisabled}
          sorts={this.sorts}
          onDoubleClick={onDoubleClick}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  userHealthFacilityFullPath: state.loc.userHealthFacilityFullPath,
  submittingMutation: state.loc.submittingMutation,
  mutation: state.loc.mutation,
  confirmed: state.core.confirmed,
  accreditations: state.accredation.accreditationProjects,
  fetchedAccreditationProjects: state.accredation.fetchedAccreditationProjects,
  fetchingAccreditationProjects: state.accredation.fetchingAccreditationProjects,
  errorAccreditationProjects: state.accredation.errorAccreditationProjects,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ coreConfirm, journalize, fetchAccreditationProjects }, dispatch);
};

export default withModulesManager(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProjectsSearcher)));
