import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import {
  withTheme,
  withStyles,
  Fab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { ProgressOrError, formatMessageWithValues, Helmet, withModulesManager, journalize } from "@openimis/fe-core"; // Grouping all imports for core utilities
import ProjectsMasterPanel from "./ProjectsMasterPanel";
import AddIcon from "@material-ui/icons/Add";
import AccreditationDataView from "./AccreditationDataView";
import QRCodeGenerator from "./QRCodeGenerator";
import QualityAssuranceDetailSearcher from "./QualityAssuranceDetailSearcher";
import { createQualityAssuranceDetail, createQAProject } from "../actions";
import { ACCREDITATION, QUALITY_ASSURRANCE } from "../constants";
import QualityAssuranceProjectMasterPanel from "./QualityAssuranceProjectMasterPanel";
const HF_FORM_CONTRIBUTION_KEY = "location.HealthFacility";

const styles = (theme) => ({
  page: theme.page,
  lockedPage: theme.page.locked,
  paper: theme.paper.paper,
  fab: theme.fab,
});

class QualityAssuranceForm extends Component {
  state = {
    selectedTab: 0,
    isDialogOpen: false,
  };

  handleChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
    }
  }

  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  handleInitiateQualityAssuranceDetail = () => {
    const { projectUuid } = this.props;
    if (projectUuid) {
      this.props.createQualityAssuranceDetail(projectUuid, "Initiated new Quality Assurance");
    }
    this.setState({ isDialogOpen: false });
  };

  createProject = () => {
    if (this.props.edited?.healthFacility) {
      this.props.createQAProject(this.props.edited?.healthFacility, "Created QA Project");
    }
  };

  render() {
    const { classes, intl, edited, updateAttribute, projectUuid, type } = this.props;
    const { selectedTab, isDialogOpen } = this.state;
    const hospital = this.props.edited?.healthFacility?.name;

    return (
      <div className={clsx(false && classes.lockedPage)}>
        <Helmet
          title={formatMessageWithValues(intl, "location", "healthFacility.edit.page.title", {
            code: "accreditation",
          })}
        />
        <ProgressOrError progress={false} error={true} />
        <Fragment>
          <QualityAssuranceProjectMasterPanel
            edited={edited}
            updateAttribute={updateAttribute}
            projectUuid={projectUuid}
            createProject={this.createProject}
            type={type}
          />

          {projectUuid && (
            <div className={classes.fab}>
              <Fab variant="contained" color="primary" onClick={this.handleOpenDialog}>
                <AddIcon />
              </Fab>
            </div>
          )}

          {projectUuid && edited?.id && (
            <div className={classes.page}>
              <QualityAssuranceDetailSearcher projectUuid={projectUuid} projectId={edited.id} />
            </div>
          )}
        </Fragment>

        <Dialog
          open={isDialogOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="initiate-accreditation-dialog-title"
        >
          <DialogTitle id="initiate-accreditation-dialog-title">Initiate New Quality Assurance</DialogTitle>
          <DialogContent>
            <DialogContentText>{`Are you sure you want to initiate a new Quality Assurance Scheme for ${hospital}?`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              No
            </Button>
            <Button onClick={this.handleInitiateQualityAssuranceDetail} color="primary" autoFocus variant="contained">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights: state.core?.user?.i_user?.rights || [],
  mutation: state.accredation.mutation,
  submittingMutation: state.accredation.submittingMutation,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createQualityAssuranceDetail, journalize, createQAProject }, dispatch);
};
export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(QualityAssuranceForm)))),
);
