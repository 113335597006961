import React from "react";
import { Box, Typography } from "@material-ui/core";

const CircularMetric = ({ passed, failed, theme, title }) => {
  const total = passed + failed;
  const passedPercentage = (passed / total) * 100;
  const failedPercentage = (failed / total) * 100;

  const circleRadius = 65; // Bigger circle
  const circumference = 2 * Math.PI * circleRadius;
  const strokeWidth = 14; // Thicker circle

  const passedOffset = circumference * (1 - passedPercentage / 100);
  const failedOffset = circumference * (1 - failedPercentage / 100);

  return (
    <Box>
      <svg width="280" height="280" viewBox="0 0 180 180">
        {/* Background Circle */}
        <circle cx="90" cy="90" r={circleRadius} stroke="#e6e6e6" strokeWidth={strokeWidth} fill="none" />

        {/* Passed (Primary Color) */}
        <circle
          cx="90"
          cy="90"
          r={circleRadius}
          stroke={theme.palette.primary.main} // Using primary color
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={passedOffset}
          strokeLinecap="round"
          transform="rotate(-90 90 90)"
        />

        {/* Failed (Light Red) */}
        <circle
          cx="90"
          cy="90"
          r={circleRadius}
          stroke="#a82e2e" // Light red color
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={circumference + failedOffset - passedOffset}
          strokeLinecap="round"
          transform="rotate(-90 90 90)"
        />

        {/* Passed Label */}
        <text x="50%" y="45%" textAnchor="middle" fontSize="16" fill={theme.palette.primary.main}>
          {`${passedPercentage.toFixed(1)}% Passed`}
        </text>

        {/* Failed Label */}
        <text x="50%" y="55%" textAnchor="middle" fontSize="16" fill="#a82e2e">
          {`${failedPercentage.toFixed(1)}% Failed`}
        </text>
      </svg>
      <Typography variant={"h5"}>{title}</Typography>
    </Box>
  );
};

export default CircularMetric;
