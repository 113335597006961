import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import QRCode from "react-qr-code";

const styles = (theme) => ({
  paper: theme.paper.paper,
  title: theme.paper.title,
  page: theme.page,
});

class QRCodeGenerator extends Component {
  render() {
    const { classes, value, size } = this.props;
    return (
      <div className={classes.page}>
        <QRCode value={value} size={size} />
      </div>
    );
  }
}

export default withTheme(withStyles(styles)(QRCodeGenerator));
