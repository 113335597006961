import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Keyboard, ScreenShare, Assignment, WatchOutlined, BallotRounded } from "@material-ui/icons";
import { formatMessage, MainMenuContribution, withModulesManager } from "@openimis/fe-core";
const ACCREDATION_MAIN_MENU_CONTRIBUTION_KEY = "accredation.MainMenu";

class AccredationMainMenu extends Component {
  render() {
    const { rights } = this.props;
    let entries = [];
    entries.push({
      text: formatMessage(this.props.intl, "accredation", "menu.accredation"),
      icon: <Keyboard />,
      route: "/accredation/accredation",
    });
    entries.push({
      text: formatMessage(this.props.intl, "accredation", "menu.qa"),
      icon: <Assignment />,
      route: "/accredation/qa",
    });
    entries.push({
      text: formatMessage(this.props.intl, "accredation", "menu.forms"),
      icon: <BallotRounded />,
      route: "/accredation/forms",
    });

    entries.push(
      ...this.props.modulesManager
        .getContribs(ACCREDATION_MAIN_MENU_CONTRIBUTION_KEY)
        .filter((c) => !c.filter || c.filter(rights)),
    );
    if (!entries.length) return null;
    return (
      <MainMenuContribution
        {...this.props}
        header={formatMessage(this.props.intl, "accredation", "mainMenu")}
        icon={<ScreenShare />}
        entries={entries}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
});
export default withModulesManager(injectIntl(connect(mapStateToProps)(AccredationMainMenu)));
