import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { Keyboard, ScreenShare, Add } from "@material-ui/icons";
import {
  formatMessage,
  MainMenuContribution,
  withModulesManager,
} from "@openimis/fe-core";

class PrAuthMainMenu extends Component {
  render() {
    const { intl } = this.props;
    let entries = [];
    entries.push({
      text: formatMessage(intl, "preauthorization", "menu.preauthorization"),
      icon: <Keyboard />,
      route: "/preauthorization/preauthorization",
    });
    if (!entries.length) return null;
    return (
      <MainMenuContribution
        {...this.props}
        header={formatMessage(intl, "preauthorization", "mainMenu")}
        icon={<ScreenShare />}
        entries={entries}
      />
    );
  }
}

export default withModulesManager(injectIntl(PrAuthMainMenu));
