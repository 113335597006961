import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import { withModulesManager, withHistory } from "@openimis/fe-core";
import { fetchQualityAssuranceDetail } from "../actions";
import QualityAssuranceDetailForm from "../components/QualityAssuranceDetailForm";

const styles = (theme) => ({
  paper: theme.paper.paper,
  title: theme.paper.title,
  page: theme.page,
});
class AccreditationDetailPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { qaUuid } = this.props?.match?.params;
    if (qaUuid) {
      this.props.fetchQualityAssuranceDetail(qaUuid);
    }
  }
  render() {
    const { classes, fetchedQADetail, fetchingQADetail, qaDetail, errorQADetail } = this.props;

    if (errorQADetail) {
      return <Typography>Something Happened</Typography>;
    }
    if (fetchingQADetail) {
      return (
        <Paper className={classes.paper}>
          <Typography className={classes.title}>Loading.....</Typography>
        </Paper>
      );
    }
    return (
      <div className={""}>
        <QualityAssuranceDetailForm edited={qaDetail && qaDetail[0]} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  module: state.core?.savedPagination?.module,
  qaDetail: state.accredation.qaDetail,
  fetchingQADetail: state.accredation.fetchingQADetail,
  fetchedQADetail: state.accredation.fetchedQADetail,
  errorQADetail: state.accredation.errorQADetail,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchQualityAssuranceDetail }, dispatch);

export default withModulesManager(
  injectIntl(
    withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(AccreditationDetailPage)))),
  ),
);
