import messages_en from "./translations/en.json";
import reducer from "./reducer";
// import PrAuthFilter from "./components/PrAuthFilter";
import AddPrauth from "./pages/AddPrauth";
import PrAuthMainMenu from "./menus/PrAuthMainMenu";
import Preauthorizations from "./pages/Preauthorizations";
import Pacode from "./reports/Pacode";

// const ROUTE_PREAUTHORIZATION = "preauthorization/my_preauthorization";
const ROUTE_ADD_PREAUTHORIZATION = "preauthorization/add_preauthorizations";
const ROUTE_PREAUTHORIZATIONS = "preauthorization/preauthorization";

const DEFAULT_CONFIG = {
  translations: [{ key: "en", messages: messages_en }],
  reducers: [{ key: "preauthorization", reducer }],
  reports: [
    {
      key: "pa_code_generated",
      component: Pacode,
      isValid: (values) =>
        values.date_from && values.date_to && values.pra_status,
      getParams: (values) => ({
        date_from: values.date_from,
        date_to: values.date_to,
        pra_status: values.pra_status,
      }),
    },
  ],

  refs: [
    // { key: "preauthorization.route.preauthorizationfilter", ref: ROUTE_PREAUTHORIZATION },
    {
      key: "preauthorization.route.preauthorizationadd",
      ref: ROUTE_ADD_PREAUTHORIZATION,
    },
    {
      key: "preauthorization.route.preauthorization",
      ref: ROUTE_PREAUTHORIZATIONS,
    },
  ],

  "core.Router": [
    // {path: ROUTE_PREAUTHORIZATION, component: PrAuthFilter},
    {
      path: ROUTE_ADD_PREAUTHORIZATION + "/:preauth_uuid?",
      component: AddPrauth,
    },
    {
      path: ROUTE_PREAUTHORIZATIONS,
      component: Preauthorizations,
    },
  ],
};

export const PreauthorizationModule = (cfg) => {
  return { ...DEFAULT_CONFIG, ...cfg };
};
