// TransferInsureesModal.js

import React, { Component } from "react";
import {
  Modal,
  Paper,
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  IconButton,
  Tooltip,
  List,
  ListItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import HealthFacilityPicker from "../pickers/HealthFacilityPicker";
import { withTheme, withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  page: theme.page,
  fab: theme.fab,
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "700px",
    maxWidth: "1500px",
  },
  paper: {
    padding: theme.spacing(2),
    width: "42%",
  },
});

class TransferInsureesModal extends Component {
  state = {
    transferTo: "",
    modalStep: 1,
  };

  onChange = (value) => {
    this.setState({ transferTo: value });
  };

  transferInsurees = () => {
    const { transferFrom, transferInsureesToSecHF, transferInsureesToSameHF, onClose } = this.props;
    const { transferTo } = this.state;

    if (!transferTo) {
      transferInsureesToSecHF(transferFrom, `Transferred Insurees from ${transferFrom?.name}`);
    } else {
      transferInsureesToSameHF({ transferFrom, transferTo }, "Insurees Transferred");
    }
    onClose();
  };

  nextStep = () => {
    this.setState({ modalStep: 2 });
  };

  previousStep = () => {
    this.setState({ modalStep: 1, transferTo: null });
  };

  render() {
    const { isOpen, onClose, transferFrom, classes } = this.props;
    const { modalStep, transferTo } = this.state;

    return (
      <Modal open={isOpen} onClose={onClose} className={classes.modal}>
        <Paper className={classes.paper}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" textAlign="center">
              Transfer Insurees
            </Typography>
            <IconButton onClick={onClose} color="inherit">
              <CloseIcon />
            </IconButton>
          </Box>

          {modalStep === 1 && (
            <>
              <List>
                <ListItem>
                  {"1. Transfer Insurees In " + transferFrom?.name + " To their Alternate Facility click Transfer"}
                </ListItem>
                <ListItem>
                  {"2. Transfer Insurees In " + transferFrom?.name + " To the same Health Facility click next"}
                </ListItem>
              </List>
              <Grid container>
                <Grid item xs={12} style={{ marginBottom: 10, paddingTop: 16 }}>
                  <TextField
                    fullWidth
                    value={transferFrom?.name}
                    variant="outlined"
                    disabled
                    InputProps={{
                      style: { fontWeight: "bold" },
                    }}
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="end" mt={2}>
                <Tooltip title="Go to the next step">
                  <Box mx={2}>
                    <Button color="primary" variant="outlined" onClick={this.nextStep}>
                      Next
                    </Button>
                  </Box>
                </Tooltip>
                <Tooltip title={`Transfer insurees from ${transferFrom?.name}`}>
                  <Box>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.transferInsurees}
                      disabled={!transferTo && modalStep === 2}
                    >
                      Transfer
                    </Button>
                  </Box>
                </Tooltip>
              </Box>
            </>
          )}

          {modalStep === 2 && (
            <>
              <List>
                <ListItem>
                  {`1. Transfer Insurees In
                    ${transferFrom?.name} 
                    To their
                    ${transferTo ? transferTo.name : '"Select a health facility"'} 
                    click Transfer`}
                </ListItem>
                <ListItem>
                  {"2. Transfer Insurees In " + transferFrom?.name + " To their Alternate Facility click Back"}
                </ListItem>
              </List>
              <Grid container>
                <Grid item xs={12} style={{ marginBottom: 10, paddingTop: 16 }}>
                  <TextField
                    fullWidth
                    value={transferFrom?.name}
                    variant="outlined"
                    disabled
                    InputProps={{
                      style: { fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 14, paddingTop: 16 }}>
                  <HealthFacilityPicker
                    onChange={this.onChange}
                    value={transferTo}
                    label="Select Health Facility To Transfer Insurees To"
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="end">
                <Tooltip title="Go back to the previous step">
                  <Box mx={2}>
                    <Button onClick={this.previousStep} color="primary" variant="outlined">
                      Back
                    </Button>
                  </Box>
                </Tooltip>
                <Tooltip
                  title={
                    !transferTo
                      ? "Select Health Facility To Transfer To"
                      : `Transfer the insurees from ${transferFrom?.name}`
                  }
                >
                  <Box>
                    <Button color="primary" variant="contained" onClick={this.transferInsurees} disabled={!transferTo}>
                      Transfer
                    </Button>
                  </Box>
                </Tooltip>
              </Box>
            </>
          )}
        </Paper>
      </Modal>
    );
  }
}

export default withTheme(withStyles(styles)(TransferInsureesModal));
