import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { withHistory, historyPush, formatMessage, Helmet, clearCurrentPaginationPage } from "@openimis/fe-core";
const MODULE_NAME = "accredation";

import ProjectsSearcher from "../components/ProjectsSearcher";

const styles = (theme) => ({
  page: theme.page,
  fab: theme.fab,
});

class AccredationsPage extends Component {
  constructor(props) {
    super(props);
    this.onAdd = this.onAdd.bind(this);
    this.onDoubleClick = this.onDoubleClick.bind(this);
  }

  // Handler for the Add button
  onAdd() {
    const { modulesManager, history } = this.props;
    if (modulesManager && history) {
      historyPush(modulesManager, history, "accreditation.route.add");
    }
  }

  // Handler for double click on an item
  onDoubleClick(project) {
    const { modulesManager, history } = this.props;
    if (modulesManager && history) {
      historyPush(modulesManager, history, "accreditation.route.add", [project.uuid]);
    }
  }

  // Lifecycle method to handle clearing pagination when module changes
  componentDidUpdate(prevProps) {
    const { module, clearCurrentPaginationPage } = this.props;
    if (module !== MODULE_NAME && module !== prevProps.module) {
      clearCurrentPaginationPage();
    }
  }

  render() {
    const { classes, intl } = this.props;

    return (
      <div className={classes.page}>
        <Helmet title={formatMessage(intl, "accredation", "projects.page.title")} />
        <ProjectsSearcher onDoubleClick={this.onDoubleClick} />

        <div className={classes.fab}>
          <Fab color="primary" onClick={this.onAdd}>
            <AddIcon />
          </Fab>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  module: state.core?.savedPagination?.module,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ clearCurrentPaginationPage }, dispatch);

export default injectIntl(
  withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(AccredationsPage)))),
);
