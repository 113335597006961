import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { STATUS } from "../constants";

class PStatusPicker extends Component {
  render() {
    return <ConstantBasedPicker module="claim" label="Status" constants={STATUS} {...this.props} />;
  }
}

export default PStatusPicker;
