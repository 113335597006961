import React, { Component } from "react";
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Fab,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  TextField,
  Radio,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import { FormPanel } from "@openimis/fe-core";

const styles = (theme) => ({
  paper: theme.paper.paper,
  title: theme.paper.title,
  formContainer: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  uploadButtonContainer: {
    border: `dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    position: "relative",
    backgroundColor: "#F4F6FF",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: theme.palette.primary.main,
    width: "100%",
  },
  hiddenInput: {
    display: "none",
  },
  fileName: {
    marginLeft: theme.spacing(2),
    width: "100%",
    textAlign: "center",
  },
});

class FormsPanel extends FormPanel {
  constructor(props) {
    super(props);
    this.state = {
      formType: "",
      form: { file: null, fileName: "" },
    };
  }

  handleChange = (event) => {
    const { type, value, files } = event.target;

    if (type === "file") {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = reader.result.split(",")[1];
          this.setState({ form: { file: base64, fileName: file.name } });
        };
        reader.readAsDataURL(file);
      }
    } else if (type === "radio") {
      this.setState({ formType: value });
    }
  };

  handleFormNameChange = (event) => {
    this.setState({ form: { ...this.state.form, fileName: event.target.value } });
  };

  handleSelectFile = () => {
    this.fileInput.click();
  };

  handleUpload = () => {
    this.props.uploadForm(this.state);
  };

  isSave = () => {
    const { formType, form } = this.state;
    return !formType || !form.file;
  };

  render() {
    const { classes } = this.props;
    const { form, formType } = this.state;

    return (
      <Box className={classes.page}>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>Upload Form</Typography>
          <Box className={classes.formContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={{ fontWeight: "bold" }}>
                    Select Form Type
                  </FormLabel>
                  <RadioGroup
                    aria-label="Form Type"
                    name="formType"
                    value={formType}
                    onChange={this.handleChange}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel value="acc_form" control={<Radio color="primary" />} label="Accreditation" />
                    <FormControlLabel value="qa_form" control={<Radio color="primary" />} label="Quality Assurance" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Box className={classes.uploadButtonContainer}>
              <input
                type="file"
                className={classes.hiddenInput}
                ref={(input) => (this.fileInput = input)}
                accept=".xml"
                onChange={this.handleChange}
              />
              <Box onClick={this.handleSelectFile} className={classes.uploadButton}>
                <CloudUploadIcon fontSize="large" />
                <Typography className={classes.fileName}>{form.fileName || `Click to add file`}</Typography>
              </Box>
            </Box>

            <TextField
              label="Form Name"
              fullWidth
              required
              value={form.fileName}
              onChange={this.handleFormNameChange}
            />

            <Box display="flex" justifyContent="end" mt={2}>
              <Button variant="contained" color="primary" disabled={this.isSave()} onClick={this.handleUpload}>
                Publish Form
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(FormsPanel);
