import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";

import { withTheme, withStyles, Tabs, Tab, Box, Modal, IconButton } from "@material-ui/core";

import { ProgressOrError, formatMessageWithValues, Helmet, withModulesManager, journalize } from "@openimis/fe-core";
import ProjectsMasterPanel from "./ProjectsMasterPanel";
import AccreditationDataView from "./AccreditationDataView";
import CloseIcon from "@material-ui/icons/Close";

import QRCodeGenerator from "./QRCodeGenerator";
import AccreditationSearcher from "./AccreditationSearcher";
import AccreditationMasterPanel from "./AccreditationMasterPanel";
import Results from "./Results";
import Formslist from "./Formslist";
import { initiateAccreditationDetail } from "../actions";
import { approveOrDeclineAccreditationDetail } from "../actions";
import UsersPanel from "./UsersPanel";
import { PROJECT_CONFIRMED, PROJECT_FORM_DEPLOYED } from "../constants";

const HF_FORM_CONTRIBUTION_KEY = "location.HealthFacility";

const styles = (theme) => ({
  page: theme.page,
  lockedPage: theme.page.locked,
  paper: theme.paper.paper,
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    outline: "none",
  },
  modalCloseButton: {
    marginTop: theme.spacing(0),
  },
});

class AccreditationForm extends Component {
  state = {
    selectedTab: 0, // Default tab
    qrCodeModalOpen: false, // State to control QR code modal
    user: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
    }
  }

  handleChange = (event, newValue) => {
    if (newValue === 2) {
      this.setState({ qrCodeModalOpen: true });
    } else {
      this.setState({ selectedTab: newValue });
    }
  };

  closeModal = () => {
    this.setState({ qrCodeModalOpen: false });
  };

  renderTabPanel(index, updateAttribute, edited) {
    switch (index) {
      case 0:
        return <Formslist uuid={edited && edited[0]?.uuid} formType={1} />;
      case 1:
        return <UsersPanel uuid={edited && edited[0]?.uuid} projectType="acc" />;
      case 3:
        return <Results />;
      case 4:
        return <AccreditationDataView edited={edited} />;
      default:
        return null;
    }
  }

  renderQRCodeModal() {
    const { qrCodeModalOpen } = this.state;
    const { classes } = this.props;

    return (
      <Modal open={qrCodeModalOpen} onClose={this.closeModal}>
        <div className={classes.modalContent}>
          <QRCodeGenerator value={"example.com"} size={300} />
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton
              onClick={this.closeModal}
              variant="contained"
              color="primary"
              className={classes.modalCloseButton}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </Modal>
    );
  }

  initiateAccreditationDetail = () => {
    const { edited } = this.props;
    if (edited && edited[0].uuid) {
      this.props.initiateAccreditationDetail(edited[0]?.uuid, "Initiated Acreditation Detail");
    }
  };

  handleApproveOrRejectAccreditation = (state, clientMutationLabel) => {
    const { edited } = this.props;
    if (edited && edited[0].uuid) {
      this.props.approveOrDeclineAccreditationDetail(edited[0]?.uuid, state, clientMutationLabel);
    }
  };

  handleUserChange = (user) => {
    this.setState({ user: user });
  };

  addUser = () => {
    if (this.state.user) {
    }
  };

  render() {
    const { classes, intl, edited, updateAttribute, projectUuid } = this.props;
    const { selectedTab } = this.state;
    return (
      <div className={clsx(false && classes.lockedPage)}>
        <Helmet
          title={formatMessageWithValues(intl, "location", "healthFacility.edit.page.title", {
            code: "accreditation",
          })}
        />
        <ProgressOrError progress={false} error={true} />

        <Fragment>
          <AccreditationMasterPanel
            edited={edited}
            updateAttribute={updateAttribute}
            initiateAccreditationDetail={this.initiateAccreditationDetail}
            handleApproveOrRejectAccreditation={this.handleApproveOrRejectAccreditation}
          />

          {
            <Tabs
              value={selectedTab}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.paper}
            >
              <Tab label="Assign Forms" /> {/* New "User" tab */}
              <Tab label="User" /> {/* New "User" tab */}
              {edited && edited?.state === PROJECT_FORM_DEPLOYED && <Tab label="QRCode" />}
              {edited && edited?.state === PROJECT_CONFIRMED && <Tab label="General Results" />}
              {edited && edited?.state === PROJECT_CONFIRMED && <Tab label="Quality Assurance Data" />}
            </Tabs>
          }

          {<Box>{this.renderTabPanel(selectedTab, updateAttribute, edited)}</Box>}

          {this.renderQRCodeModal()}
        </Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  mutation: state.accredation.mutation,
  submittingMutation: state.accredation.submittingMutation,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ initiateAccreditationDetail, approveOrDeclineAccreditationDetail, journalize }, dispatch);
};

export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(AccreditationForm)))),
);
